import React from 'react'
import { DeckGlOverlay } from "./deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";
import { getServiceColorRgb } from "../../utils/common";

function getDeckGlLayers(data) {
  if (!data) return [];

  return [
    new GeoJsonLayer({
      id: "timelineHoveredGeoJsonLayer",
      data: data,
      stroked: true,
      filled: true,
      getLineWidth: 1,
      opacity: 1,
      getFillColor: (f) => {
        return f?.properties?.serviceType == 'obstacle' ? [0,0,0,120] : getServiceColorRgb(f?.properties?.serviceType)
      },
      getLineColor: (f) => getServiceColorRgb(f?.properties?.serviceType),
    }),
  ];
}
const TimelineHoveredPolygonLayer = ({hoveredGeoJson}) => {
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(hoveredGeoJson)}
    />
  )
}

export default TimelineHoveredPolygonLayer