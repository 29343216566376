import React, { useState } from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import { Box, Divider } from "@mui/material";
import NestedFolderList from "./NestedFolderList";
import FolderList from "./FolderList";

const SavedPlans = () => {
  const locationGroupData = useSelector(
    (state) => state.workPlan.locationGroupData
  );
  const [currentPath, setCurrentPath] = useState('');

  const handleFolderClick = (path) => {
    setCurrentPath(path);
  };

  const handleBreadcrumbClick = (path) => {
    setCurrentPath(path);
  };
  return (
    <div>
      {" "}
      <Box my={2}>
        <Breadcrumbs
          path={currentPath}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
        <Divider />
        {currentPath === '' ? (
        <FolderList onFolderClick={handleFolderClick} />
      ) : (
        <NestedFolderList folderPath={currentPath} onFolderClick={handleFolderClick} />
      )}
      </Box>
    </div>
  );
};

export default SavedPlans;
