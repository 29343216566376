import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { useMap } from "@vis.gl/react-google-maps";
import { useEffect, useMemo, useState } from "react";

/**
 * A very simple implementation of a component that renders a list of deck.gl layers
 * via the GoogleMapsOverlay into the <Map> component containing it.
 */
export const DeckGlOverlay = ({ layers }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const map = useMap();

  // Initialize the GoogleMapsOverlay instance
  const deck = useMemo(() => new GoogleMapsOverlay({ interleaved: true }), []);

  // Check if the map has loaded
  useEffect(() => {
    if (map) {
      const listener = map.addListener('tilesloaded', () => {
        setMapLoaded(true);
      });

      // Clean up the listener when the component is unmounted or map changes
      return () => listener.remove();
    }
  }, [map]);

  // Add the overlay to the map once the map is available and fully loaded
  useEffect(() => {
    if (mapLoaded) {
      deck.setMap(map);

      // Clean up the overlay when the component is unmounted or map changes
      return () => {
        deck.setMap(null);
      };
    }
  }, [deck, map, mapLoaded]);

  // Update the overlay layers whenever the layers prop changes
  useEffect(() => {
    if (mapLoaded) {
      deck.setProps({ layers });
    }
  }, [deck, layers, mapLoaded]);

  // No DOM rendered by this component
  return null;
};
