import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ParkingCard from "./ParkingCard";
import { handleNextStep, setParkings } from "../../../slices/stepperFormSlice";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import ParkingModal from "../../../Modals/ParkingModal/ParkingModal";
import {
  useDeleteParkingMutation,
  useEditParkingMutation,
  useGetParkingsQuery,
  useLazyGetParkingsQuery,
  useOrderStatusMutation,
} from "../../../services/OpenApi";
import { useParams } from "react-router-dom";

const AddParkingForm = () => {
  const {
    stepperForm: { parkings },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const params = useParams();
  const user = useSelector((state) => state.global.user);
  const [getParkings] = useLazyGetParkingsQuery();
  const { data: parkingsData } = useGetParkingsQuery({
    orderId: params?.id,
    orgName: user?.organization,
  });
  const [deleteParking] = useDeleteParkingMutation();
  const [orderStatus] = useOrderStatusMutation();
  const [editParking] = useEditParkingMutation();
  const [modalOpenForId, setModalOpenForId] = useState(null);
  const [isParkingModalOpen, setIsParkingModalOpen] = useState(false);
  const [editParkingData, setEditParkingData] = useState(null);

  const handleEdit = (id) => {
    const parking = parkings.filter((parking) => parking.id === id);
    setEditParkingData(parking[0]);
    setIsParkingModalOpen(true);
  };

  const handleDelete = (id) => {
    setModalOpenForId(id);
  };
  const handleRemoveParking = (id) => {
    // dispatch(setParkings(parkings.filter((parking) => parking.id !== id)));
    deleteParking({ id: id, orgName: user?.organization });
    setModalOpenForId(null);
  };
  const editParkingFunc = async (
    parkingName,
    parkingType,
    address,
    parkingCoordinates,
    id
  ) => {
    const updatedParking = parkings.map((parking) => {
      if (parking?.id === id) {
        return {
          ...parking,
          parkingName: parkingName,
          parkingType: parkingType,
          address: address,
          points: {
            lat: parkingCoordinates?.lat,
            lon: parkingCoordinates?.lng,
          },
        };
      }
      return parking;
    });
    try {
      await editParking({
        id: id,
        orgName: user?.organization,
        content: {
          parkingName: parkingName,
          parkingType: parkingType,
          address: address,
          points: {
            lat: parkingCoordinates?.lat,
            lon: parkingCoordinates?.lng,
          },
        },
      });
    } catch (err) {
      console.log("error => ", err);
    }
    // dispatch(setParkings(updatedParking));
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getParkings({
          orderId: params?.id,
          orgName: user?.organization,
        }).unwrap();
        if (response) {
          dispatch(setParkings(response?.data));
          // dispatch(handleNextStep());
        }
      } catch (err) {
        console.log("error => ", err);
      }
    })();
  }, [getParkings]);
  useEffect(() => {
    if (parkingsData) {
      dispatch(setParkings(parkingsData?.data));
    }
  }, [parkingsData]);

  const onSubmitParkings = async () => {
    try {
      const response = await orderStatus({
        orderId: params?.id,
        orgName: user?.organization,
        status: "PARKING"
      });
      dispatch(handleNextStep())
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="StepsFormContainer">
      <h3 className="StepsFormContainerHeading">Add Parkings</h3>
      {parkings?.map((parking) => (
        <>
          <ParkingCard
            key={parking.id}
            parking={parking}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
          {modalOpenForId === parking?.id && (
            <ConfirmationModal
              isOpen={modalOpenForId}
              setIsOpen={() => setModalOpenForId(null)}
              title="Remove Parking"
              body="Are you sure you want to remove this parking?"
              handleOk={() => handleRemoveParking(parking.id)}
            />
          )}
          {isParkingModalOpen && (
            <ParkingModal
              isOpen={isParkingModalOpen}
              setIsOpen={setIsParkingModalOpen}
              submitFunction={editParkingFunc}
              editData={editParkingData}
            />
          )}
        </>
      ))}
      <Button
        type="submit"
        fullWidth
        //   disabled={now.length === 0}
        variant="contained"
        onClick={onSubmitParkings}
        sx={{ mt: 3, mb: 2 }}
      >
        {/* {isLoading ? <CircularProgress size={24} /> : "Log In"} */}
        Confirm
      </Button>
    </div>
  );
};

export default AddParkingForm;
