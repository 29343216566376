import React, { useEffect, useState } from "react";
import { ControlPosition, Map, MapControl } from "@vis.gl/react-google-maps";

import { useDrawingManager } from "./useDrawingManager";
import { UndoRedoControl } from "./UndoRedoControl";
import { Box } from "@mui/material";
import SearchBar from "../../components/SearchBar/SearchBar";
import LocationCoordinateMarker from "./LocationCoordinateMarker";
import { useDispatch, useSelector } from "react-redux";
import {
  handleNextStep,
  setLocationName,
  setLocationPinCoordinate,
  setStepsLabels,
} from "../../slices/stepperFormSlice";
import ParkingsCoordinatesMarker from "./ParkingsCoordinatesMarker";
import ParkingModal from "../../Modals/ParkingModal/ParkingModal";
import axios from "axios";
import { useAddParkingMutation } from "../../services/OpenApi";
import { useParams } from "react-router-dom";

const SurveyMap = () => {
  const {
    stepperForm: { activeStep, locationPinCoordinate, parkings },
  } = useSelector((state) => state);
  const [addParking, { isLoading }] = useAddParkingMutation();

  const drawingManager = useDrawingManager({
    isVisible: activeStep === 2,
  });
  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 12,
    width: 800,
    height: 400,
    bearing: 0,
    mapTypeId: "satellite", // Change this value for different map views
    fullscreenControlOptions: true,
  });
  const dispatch = useDispatch();
  const params = useParams();
  const user = useSelector((state) => state.global.user);
  const [isParkingModalOpen, setIsParkingModalOpen] = useState();
  const [parkingCoordinates, setParkingCoordinates] = useState();
  const [parkingAddress, setParkingAddress] = useState();
  const onPlaceSelected = (place) => {
    if (place.geometry && place.geometry.location) {      
      dispatch(
        setLocationPinCoordinate({
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        })
      );
      dispatch(setLocationName(place?.formatted_address));
      dispatch(
        setStepsLabels({ id: 1, description: place?.formatted_address })
      );
      dispatch(handleNextStep());
    }
  };

  useEffect(()=>{
    if(locationPinCoordinate){
      setViewport((prev) => ({
        ...prev,
        center: locationPinCoordinate,
        zoom: 18,
      }));
    }
  },[locationPinCoordinate])

  const searchBoxContainerStyle = {
    position: "absolute",
    top: "9vh",
    left: "50%",
    transform: "translateX(-50%)",
    width: "300px",
    zIndex: "10", // Ensure it is on top of the map
  };

  const handleMapClick = (event) => {
    if (event && activeStep === 1) {
      dispatch(
        setLocationPinCoordinate({
          lat: event?.detail?.latLng?.lat,
          lng: event?.detail?.latLng?.lng,
        })
      );
    }
    if (event && activeStep === 3) {
      setParkingCoordinates({
        lat: event?.detail?.latLng?.lat,
        lng: event?.detail?.latLng?.lng,
      });
      setIsParkingModalOpen(true);
    }
  };
  const addParkingFunc = async (parkingName, parkingType, address) => {
    const newParking = {
      parkingName: parkingName,
      parkingType: parkingType,
      address: address,
      points: {
        lat: parkingCoordinates?.lat,
        lon: parkingCoordinates?.lng,
      },
    };
    try {
      await addParking({
        orderId: params?.id,
        orgName: user?.organization,
        content: newParking,
      });
    } catch (err) {
      console.log("error => ", err);
    }
  };

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get(
          // `https://api.mapbox.com/geocoding/v5/mapbox.places/${parkingCoordinates.lng},${parkingCoordinates.lat}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${parkingCoordinates.lng},${parkingCoordinates.lat}.json?access_token=pk.eyJ1IjoiYXR1bGt1bXIiLCJhIjoiY2xyYWNzN2JoMDhyazJsbnh3MWR1aWU2MCJ9.BDBKCFichBcahbwbDOxmjA`
        );
        const data = response.data;
        if (data.features.length > 0) {
          const placeName = data.features[0].place_name; // This is a full text description of the place
          setParkingAddress(placeName);
        } else {
          setParkingAddress("No address found");
        }
      } catch (error) {}
    };

    fetchAddress();
  }, [parkingCoordinates]);
  return (
    <>
      <Box sx={searchBoxContainerStyle}>
        {!locationPinCoordinate && (
          <SearchBar onPlaceSelected={onPlaceSelected} />
        )}
      </Box>
      <Map
        gestureHandling={"greedy"}
        {...viewport}
        zoom={viewport?.zoom}
        onCameraChanged={(v) => setViewport(v)}
        center={viewport?.center}
        onClick={(event) => handleMapClick(event)}
        mapId={"terrain"}
        defaultBounds={{
          south: 40.5,
          west: -74.2,
          north: 40.9,
          east: -73.8,
        }}
      >
        {locationPinCoordinate && (
          <LocationCoordinateMarker coordinate={locationPinCoordinate} />
        )}
        {parkings.length > 0  & activeStep > 2 && (
          <ParkingsCoordinatesMarker parkings={parkings} />
        )}
        {activeStep === 2 && (
          <MapControl position={ControlPosition.TOP_RIGHT}>
            <UndoRedoControl drawingManager={drawingManager} readOnly={false} />
          </MapControl>
        )}
        {activeStep > 2 && (
          <MapControl position={ControlPosition.TOP_RIGHT}>
            <UndoRedoControl drawingManager={drawingManager} readOnly={true} />
          </MapControl>
        )}
        {isParkingModalOpen && (
          <ParkingModal
            isOpen={isParkingModalOpen}
            setIsOpen={setIsParkingModalOpen}
            parkingAddressVal={parkingAddress}
            submitFunction={addParkingFunc}
            parkingCoordinates={parkingCoordinates}
          />
        )}
      </Map>
    </>
  );
};

export default SurveyMap;
