import React from "react";
import {
  convertTimestampToFormattedDate,
  getCrewMemberName,
  getEquipmentName,
  getServiceName,
} from "../../utils/common";
import { InfoWindow } from "@vis.gl/react-google-maps";

const PolygonDetailsPopup = ({
  selectedPolygon,
  clickedPolygonData,
  setClickedPolygonData,
  lookUpData,
}) => {
  return (
    <>
      {selectedPolygon && (
        <InfoWindow
          position={{lat: selectedPolygon?.entryPoint?.lat, lng: selectedPolygon?.entryPoint?.lon}}
          offsetTop={-30}
          closeButton={false}
          closeOnClick={false}
        >
          <div>
            <p>Path Id : {selectedPolygon?.pathId}</p>
            <p>
              Arrival Time :{" "}
              {convertTimestampToFormattedDate(selectedPolygon?.arrivalTime)}
            </p>
            <p>
              Departure Time :{" "}
              {convertTimestampToFormattedDate(selectedPolygon?.departureTime)}
            </p>
            <p>
              Travel Distance :{" "}
              {selectedPolygon?.travelDistance}
            </p>
            <p>Entry Point : lat {selectedPolygon?.entryPoint?.lat}, lon {selectedPolygon?.entryPoint?.lon}  </p>
            <p>Exit Point : lat {selectedPolygon?.exitPoint?.lat},lon {selectedPolygon?.exitPoint?.lon}  </p>
            <p>Service Name : {selectedPolygon?.serviceName}</p>
            <p>Equipment Name : {selectedPolygon?.equipmentName}</p>
          </div>
        </InfoWindow>
      )}

      {clickedPolygonData && (
        <InfoWindow
          position={{lat: clickedPolygonData?.coordinates?.[1], lng: clickedPolygonData?.coordinates?.[0]}}
          anchor="left"
          pixelOffset={[0, -20]}
          disableAutoPan={true}
          shouldFocus={false}
          onClose={()=> setClickedPolygonData(null)}
          closeButton={false}
        >
          <div className="hoveredRow">
            <span className="hoveredSegmentTitle">Crew Member</span>
            <span className="hoveredSegmentValue">
              {getCrewMemberName(clickedPolygonData?.crewMemberId, lookUpData)}
            </span>
          </div>
          <div className="hoveredRow">
            <span className="hoveredSegmentTitle">Service</span>
            <span className="hoveredSegmentValue">
              {getServiceName(clickedPolygonData?.serviceId, lookUpData)}
            </span>
          </div>
          <div className="hoveredRow">
            <span className="hoveredSegmentTitle">Equipment</span>
            <span className="hoveredSegmentValue">
              {getEquipmentName(clickedPolygonData?.crewEquipmentId, lookUpData)}
            </span>
          </div>
          <div className="hoveredRow">
            <span className="hoveredSegmentTitle">Polygon Id</span>
            <span className="hoveredSegmentValue">
              {clickedPolygonData?.properties?.pathId}
            </span>
          </div>
          <div className="hoveredRow">
            <span className="hoveredSegmentTitle">
              {clickedPolygonData?.properties?.measuredType}
            </span>
            <span className="hoveredSegmentValue">
              {clickedPolygonData?.properties?.measuredQty?.toFixed(0)}{" "}
              {clickedPolygonData?.properties?.measuredType === "area"
                ? "ft²"
                : "ft"}
            </span>
          </div>
          <div className="hoveredRow">
            <span className="hoveredSegmentTitle">Start Time</span>
            <span className="hoveredSegmentValue">
              {convertTimestampToFormattedDate(clickedPolygonData?.arrivalTime)}
            </span>
          </div>
          <div className="hoveredRow">
            <span className="hoveredSegmentTitle">End Time</span>
            <span className="hoveredSegmentValue">
              {convertTimestampToFormattedDate(clickedPolygonData?.departureTime)}
            </span>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default PolygonDetailsPopup;
