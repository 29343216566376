import React, { useState } from "react";
import { Chip, Stack } from "@mui/material";
import { MdEdit, MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import { setCrews } from "../../../slices/createCrewSlice";

export const setSkills = (level) => {
  switch (level) {
    case 1:
      return "Novice";
    case 2:
      return "Novice";
    case 3:
      return "Intermediate";
    case 4:
      return "Intermediate";
    case 5:
      return "Expert";
    default:
      return "Intermediate";
  }
};

const CrewMemberList = ({
  crewMembers,
  crews,
  crewIndex,
  openModalToEdit,
  isReadOnly,
}) => {
  const [modalOpenForId, setModalOpenForId] = useState(null);
  const dispatch = useDispatch();
  const isSolving = useSelector((state) => state.createCrew.isSolving);
  const handleRemoveCrewMember = (memberId) => {
    const newCrews = [...crews];
    const updatedCrewMembers = newCrews[crewIndex].crewMembers.filter(
      (member) => member.crewMemberId !== memberId
    );
    const updatedCrew = {
      ...newCrews[crewIndex],
      crewMembers: updatedCrewMembers,
      crewMemberCount: updatedCrewMembers.length,
    };
    newCrews[crewIndex] = updatedCrew;
    dispatch(setCrews(newCrews));
  };

  return (
    <div>
      {crewMembers.map((member, index) => (
        <div className="crewMemberDetailsCard" key={index}>
          <div className="crewMemberDetailsCardLeft">
            <div className="crewMemberTitle">{member.crewMemberTitle}</div>
            <div className="crewMemberName">{member.crewMemberName}</div>
            <div className="chipContainer">
              {member.crewSkills.map((skill, idx) => {
                return (
                  <Chip
                    key={idx}
                    label={`${
                      skill?.skillName ? skill?.skillName : skill?.description
                    } - ${setSkills(skill.skillLevel)}`}
                  />
                );
              })}
            </div>
          </div>
          {!isReadOnly && (
            <div className="crewMemberDetailsCardRight">
              <div className={isSolving ? "disabledClass" : "activeClass"}>
                <MdEdit
                  onClick={() => openModalToEdit(member)}
                  color="grey"
                  size={30}
                />
              </div>
              <div className={isSolving ? "disabledClass" : "activeClass"}>
                <MdDelete
                  onClick={() => setModalOpenForId(member.crewMemberId)}
                  color="grey"
                  size={30}
                />
              </div>
            </div>
          )}
          {modalOpenForId === member.crewMemberId && (
            <ConfirmationModal
              isOpen={true}
              setIsOpen={() => setModalOpenForId(null)}
              title="Remove Crew Member"
              body="Are you sure you want to remove this crew member?"
              handleOk={() => handleRemoveCrewMember(member.crewMemberId)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default CrewMemberList;
