import React from 'react';

function Circle({ color = '#3FB1CE', text="En", opacity=1.0}) {
  const pinStyle = {
    display: opacity === 0 ? 'none' : 'block',
    opacity: opacity,
  };

  return (
    <svg display="block" height="41px" width="27px" viewBox="0 0 27 41" style={pinStyle}>
      <circle fill="white" cx="14.5" cy="14.5" r="9.5" stroke={color} strokeWidth="2">
      </circle>
      {text && <text x="14.5" y="18" textAnchor="middle" fill="#333" fontSize="10" opacity="0.9">{text}</text>}
    </svg>
  );
}

export default React.memo(Circle);
