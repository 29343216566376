import { createSlice } from "@reduxjs/toolkit";
import { geoJSONToShape, shapeToGeoJSON } from "../utils/common";
import {
  isMarker,
  isPolygon,
  isPolyline,
} from "../screens/GoogleMapsDraw/types";

const initialState = {
  past: [],
  now: [],
  future: [],
};

const drawingSlice = createSlice({
  name: "drawing",
  initialState,
  reducers: {
    setOverlay: (state, action) => {
      const { overlay } = action.payload;

      const snapshot = {};
      if (isMarker(overlay)) {
        snapshot.position = overlay.getPosition()?.toJSON();
      } else if (isPolygon(overlay) || isPolyline(overlay)) {
        snapshot.path = overlay.getPath()?.getArray();
      }
      state.past.push(state.now);
      state.now = [
        ...state.now,
        {
          type: action.payload.type,
          geometry: action.payload.overlay,
          snapshot,
          geojson: shapeToGeoJSON(overlay),
        },
      ];
    },
    updateOverlays: (state) => {
      const overlays = state.now.map((overlay) => {
        const snapshot = {};
        const { geometry } = overlay;

        if (isMarker(geometry)) {
          snapshot.position = geometry.getPosition()?.toJSON();
        } else if (isPolygon(geometry) || isPolyline(geometry)) {
          snapshot.path = geometry.getPath()?.getArray();
        }

        return {
          ...overlay,
          snapshot,
          geojson: shapeToGeoJSON(geometry), // Convert shape to GeoJSON
        };
      });
      state.past.push(state.now);
      state.now = overlays;
      state.future = [];
    },
    undo: (state) => {
      if (state.past.length > 0) {
        const last = state.past.pop();
        state.future.push([...state.now]);
        state.now = last;
      }
    },
    redo: (state) => {
      const next = state.future.pop();

      if (!next) return state;

      state.past = state.now ? [...state.past, state.now] : state.past;
      state.now = next;
    },
    initializeFromGeoJSON: (state, action) => {
      const { parcelGeoJSON, drawingManager, addUpdateListener, readOnly } =
        action.payload;
      const overlays = parcelGeoJSON.features.map((feature) =>
        geoJSONToShape(
          feature,
          drawingManager.getMap(),
          (eventName, overlay) => addUpdateListener(eventName, { overlay }),
          readOnly
        )
      );

      state.now = overlays.map((overlay) => {
        const snapshot = {};
        const { geometry } = overlay;

        if (isMarker(geometry)) {
          snapshot.position = geometry.getPosition()?.toJSON();
        } else if (isPolygon(geometry) || isPolyline(geometry)) {
          snapshot.path = geometry.getPath()?.getArray();
        }

        return {
          ...overlay,
          snapshot,
          geojson: shapeToGeoJSON(geometry),
        };
      });
    },
  },
});

export const { setOverlay, updateOverlays, undo, redo, initializeFromGeoJSON } =
  drawingSlice.actions;

export default drawingSlice.reducer;
