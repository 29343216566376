import { Outlet, useNavigate } from "react-router-dom";
import { checkIfLogin } from "./common";
import { reset as resetGlobal } from "../slices/globalSlice";
import { reset as resetWorkPlan } from "../slices/workPlanSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!checkIfLogin()) {
      navigate("/login");
      localStorage.clear();
      dispatch(resetGlobal());
      dispatch(resetWorkPlan());
    }
  }, [navigate]);

  return checkIfLogin() ? <Outlet /> : null;
}
