import React, { useEffect } from "react";
import "./Survey.css";
import { APIProvider } from "@vis.gl/react-google-maps";
import SurveyMap from "../GoogleMapsDraw/SurveyMap";
import StepperForm from "../../components/StepperForm/StepperForm";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SiteDetailsForm from "./StepsForm/SiteDetailsForm";
import CreateParcelForm from "./CreateParcel/CreateParcelForm";
import AddParkingForm from "./AddParking/AddParkingForm";
import { useParams } from "react-router-dom";
import AddServicesAndEquipmentForm from "./AddServicesAndEquipmentForm/AddServicesAndEquipmentForm";
import {
  useLazyGetParcelQuery,
  useLazyGetSpecificOrderQuery,
} from "../../services/OpenApi";
import { stepperStatus } from "../../utils/common";
import { setActiveStep, setLocationPinCoordinate, setParcelGeoJSON } from "../../slices/stepperFormSlice";
import AddServicesAndEquipmentForm2 from "./AddServicesAndEquipmentForm/AddServicesAndEquipmentForm2";

const Survey = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.global.user);
  const [getSpecificOrder] = useLazyGetSpecificOrderQuery();
  const [getParcel] = useLazyGetParcelQuery();
  const {
    stepperForm: { activeStep, locationPinCoordinate },
  } = useSelector((state) => state);

  const getOrder = async (id) => {
    try {
      const response = await getSpecificOrder({
        orderId: id,
        orgName: user.organization,
      });
      if(response) {
        // const status = response.data.data?.status;
        dispatch(setLocationPinCoordinate({
          lat: response?.data?.data?.locationCoordinates?.lat,
          lng: response?.data?.data?.locationCoordinates?.lon
        }))
        dispatch(setActiveStep(stepperStatus[response.data.data?.status] + 1))
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getParcelFunc = async (id) => {
    try {
      const response = await getParcel({
        orderId: id,
        orgName: user.organization,
      });
      if(response) {
        // const status = response.data.data?.status;
        dispatch(setParcelGeoJSON(response?.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (params?.id && user?.organization) {
      getOrder(params.id);
      getParcelFunc(params.id);
    }
  }, [user, params]);

  return (
    <div className="surveyContainer">
      {locationPinCoordinate && (
        <div>
          <div className="stepperContianer">
            <StepperForm />
          </div>
          <div className="stepDetailsContianer">
            <Box sx={{ width: 360 }}>
              {activeStep === 1 && <SiteDetailsForm />}
              {activeStep === 2 && <CreateParcelForm />}
              {activeStep === 3 && <AddParkingForm />}
              {/* {activeStep === 4 && <AddServicesAndEquipmentForm />} */}
              {activeStep === 4 && <AddServicesAndEquipmentForm2 />}
            </Box>
          </div>
        </div>
      )}
      <APIProvider apiKey="AIzaSyDVIMgTN3Ul0B78ZnXbj_tb37j8BjzzrFY">
        <SurveyMap />
      </APIProvider>
    </div>
  );
};

export default Survey;
