import React, { useEffect, useRef, useState } from "react";
import Timeline from "react-visjs-timeline";
import "vis/dist/vis.min.css";
import {
  convertStartAndEndTimes,
  convertTimestampToFormattedDate,
} from "../../utils/common";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const IndividualCrewMemberTimeline = ({
  currentCrewMemberData,
  currentDay,
}) => {
  const timelineRef = useRef();
  const params = useParams();
  const lookUp = useSelector((state) => state.global.lookUpData);
  const [groupData, setGroupData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [options, setOptions] = useState({
    stack: false,
    maxHeight: 700,
    zoomMin: 1000,
    zoomMax: 100000000000,
    orientation: { axis: "top", item: "bottom" },
    groupOrder: "content",
    showTooltips: true,
    verticalScroll: true,
  });

  const getCrewMemberName = (crewMemberId) => {
    const crewData = lookUp?.crewMembers;
    if (crewData[crewMemberId]) {
      return crewData[crewMemberId].name;
    } else {
      return "Crew member not found";
    }
  };

  const getServiceName = (serviceId) => {
    const servicesData = lookUp?.services;
    if (servicesData[serviceId]) {
      return servicesData[serviceId].serviceName;
    } else {
      return "Service not found";
    }
  };
  const getEquipmentName = (crewEquipmentId) => {
    const equipments = lookUp?.equipments;
    if (equipments[crewEquipmentId]) {
      return equipments[crewEquipmentId].type;
    } else {
      return "Equipment not found";
    }
  };
  const transformDataForTimelineSummary = (lookup) => {
    const crewMemberDayWise = lookup.crewMemberDayWise;
    const timelineData = [];

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      const DayPolygons = crewData.day.filter(
        (dayData) => dayData.day == currentDay
      );

      DayPolygons.forEach((dayData) => {
        const polygons = dayData.polygon;
        let currentServiceName = null;
        let currentServiceId = null;
        let currentStartTime = null;
        let currentEndTime = null;
        let currentCrewMemberName = null;
        let currentCrewMemberId = null;
        let currentPolygonId = null;

        polygons.forEach((polygonId, index) => {
          const polygon = lookup.polygons[polygonId];

          if (currentServiceName === null) {
            // This is the first polygon

            currentServiceName = getServiceName(polygon?.serviceId);
            currentServiceId = polygon.serviceId;
            currentStartTime = polygon.arrivalTime;
            currentCrewMemberId = polygon.crewMemberId;
            currentCrewMemberName = getCrewMemberName(polygon.crewMemberId);
            currentPolygonId = polygonId;
          } else if (
            currentServiceName === getServiceName(polygon?.serviceId)
          ) {
            // Same service, update the end time
            currentEndTime = polygon.departureTime;
          } else {
            // Service name changed, push the previous task and start a new one
            pushTask(
              currentPolygonId,
              currentServiceName,
              currentServiceId,
              currentStartTime,
              currentEndTime,
              currentCrewMemberName,
              currentCrewMemberId
            );

            // Check for time gap and add a task for the gap
            if (index >= 1) {
              const previousPolygonId = polygons[index - 1];
              const previousPolygon = lookup.polygons[previousPolygonId];
              const previousPolygonServiceName = getServiceName(
                previousPolygon.serviceId
              );
              const gapStartTime = previousPolygon.departureTime;
              const gapEndTime = polygon.arrivalTime;

              pushTask(
                previousPolygonId,
                previousPolygonServiceName,
                previousPolygon.serviceId,
                gapStartTime,
                gapEndTime,
                currentCrewMemberName,
                currentCrewMemberId,
                "Gap"
              );
            }

            currentServiceName = getServiceName(polygon?.serviceId);
            currentStartTime = polygon.arrivalTime;
            currentServiceId = polygon.serviceId;
            currentCrewMemberId = polygon.crewMemberId;
            currentCrewMemberName = getCrewMemberName(polygon.crewMemberId);
            currentPolygonId = polygonId;
          }

          // Always update the end time to the current polygon's departure time
          currentEndTime = polygon.departureTime;

          // If it's the last polygon, push the task
          if (index === polygons.length - 1) {
            pushTask(
              currentPolygonId,
              currentServiceName,
              currentServiceId,
              currentStartTime,
              currentEndTime,
              currentCrewMemberName,
              currentCrewMemberId
            );
          }
        });
      });

      function pushTask(
        polygonId,
        serviceName,
        serviceId,
        startTime,
        endTime,
        crewMemberName,
        crewMemberId,
        gap
      ) {
        const task = {
          id:
            gap === "Gap"
              ? `Back To Truck ${polygonId} ${crewMemberName}`
              : `${polygonId} ${crewMemberName}`,
          content:
            gap === "Gap" ? "Back To Truck" : serviceName.replace("_", " "), // Change content for Gap
          start: convertTimestampToFormattedDate(startTime),
          end: convertTimestampToFormattedDate(endTime),
          group: `${crewMemberId}`,
          // title: `${crewMemberName} ${serviceName.replace('_', ' ')}`,
          title: `<div>
          <div class="individualContainer">
          <div>Crew Member : ${crewMemberName}</div>
          <div>Service Name : ${
            gap === "Gap" ? "Back To Truck" : serviceName.replace("_", " ")
          }</div>
          <div>Start Time : ${convertTimestampToFormattedDate(startTime)}</div>
          <div>End Time : ${convertTimestampToFormattedDate(endTime)}</div>
          </div>
          </div>`,
          className:
            gap === "Gap" ? "Back_To_Truck" : serviceName.replace(/\s/g, "_"), // Custom CSS class
        };

        timelineData.push(task);
      }
    }

    return timelineData;
  };

  const transformGroupDataForTimeline = (lookup) => {
    const crewMemberDayWise = lookup.crewMemberDayWise;
    const crewMembers = lookup.crewMembers;
    const groupData = [];

    for (const crewMemberId in crewMembers) {
      const crewMemberData = crewMembers[crewMemberId];
      const nestedGroups = [];

      const group = {
        id: crewMemberId,
        content: `${crewMemberData?.name}`,
        showNested: true,
        // className: 'timeline-event', // You can define custom CSS classes here
      };
      groupData.forEach((item) => {
        if (item.id.includes(crewMemberData?.name)) {
          nestedGroups.push(item.id);
        }
      });

      const containsName = groupData.some((item) =>
        item.id.includes(crewMemberData?.name)
      );

      if (containsName) {
        group.nestedGroups = nestedGroups;
      }
      if (crewMemberId == params.id) {
        groupData.push(group);
      }
    }
    return groupData;
  };

  const findMinMaxTimeFromLookup = (lookup) => {
    let minStartTime = null;
    let maxEndTime = null;
    const crewMemberDayWise = lookup.crewMemberDayWise;

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      crewData.day.forEach((dayData) => {
        const polygons = dayData.polygon;

        polygons.forEach((polygonId) => {
          const polygon = lookup.polygons[polygonId];
          const startDate = convertTimestampToFormattedDate(
            polygon.arrivalTime
          );
          const endDate = convertTimestampToFormattedDate(
            polygon.departureTime
          );
          if (!minStartTime || startDate < minStartTime) {
            minStartTime = startDate;
          }
          if (!maxEndTime || endDate > maxEndTime) {
            maxEndTime = endDate;
          }
        });
      });
    }

    return { minStartTime, maxEndTime };
  };

  useEffect(() => {
    if (currentCrewMemberData) {
      let lookupData = { ...lookUp };
      const timelineDataSummary = transformDataForTimelineSummary(lookupData);
      setItemsData(timelineDataSummary);
      const groupData = transformGroupDataForTimeline(lookupData);
      setGroupData(groupData);
      const bounds = findMinMaxTimeFromLookup(lookupData);
      const chartStart = new Date(bounds.minStartTime);
      const chartEnd = new Date(bounds.maxEndTime);
      const adjustedTimes = convertStartAndEndTimes(chartStart, chartEnd);
      const clusterOpts = {
        start: adjustedTimes.start,
        end: adjustedTimes.end,
      };
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...clusterOpts,
      }));
    }
  }, [currentCrewMemberData]);

  return (
    <div style={{ marginTop: "20px" }}>
      {groupData && (
        <Timeline
          ref={timelineRef}
          options={options}
          groups={groupData}
          items={itemsData}
        />
      )}
    </div>
  );
};

export default IndividualCrewMemberTimeline;
