import React from "react";
import { DeckGlOverlay } from "./deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";

function getDeckGlLayers(data) {
  if (!data) return [];
  return [
    new GeoJsonLayer({
      id: "mapHoveredGeoJsonLayer",
      data: data,
      stroked: true,
      filled: true,
      getLineWidth: 0.5,
      lineWidthMinPixels: 1,
      opacity: 1,
      getFillColor: [255, 255, 255, 200],
      getLineColor: [0, 0, 0, 0]
    })
  ];
}

const MapHoveredPolygonLayer = ({ hoveredGeoJson }) => {
  
  return <DeckGlOverlay layers={getDeckGlLayers(hoveredGeoJson)} />;
};

export default MapHoveredPolygonLayer;
