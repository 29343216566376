import React, { useEffect, useMemo, useRef, useState } from "react";
import { Timeline } from "vis-timeline/standalone";
import { DataSet } from "vis-data";
import "vis/dist/vis.min.css";
import {
  convertStartAndEndTimes,
  convertTimestampToFormattedDate,
  getFirstLetter,
} from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPolygon } from "../../slices/globalSlice";

const MapTimeline = ({ onBarHover, onBarLeave }) => {
  const timelineRef = useRef();
  const dispatch = useDispatch();
  const lookUp = useSelector((state) => state.global.lookUpData);
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const [groupData, setGroupData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [options, setOptions] = useState({
    stack: false,
    // height: "100%",
    maxHeight: "35vh",
    zoomMin: 10000, // Adjust as necessary
    // zoomMax: 50000000,
    showMajorLabels: false,
    orientation: { axis: "top", item: "top" },
    groupOrder: "content",
    showTooltips: true,
    verticalScroll: true,
    selectable: true,
    groupHeightMode: "fitItems",
    autoResize: true,
    groupOrder: function (a, b) {
      return b.value - a.value;
    },
    // hiddenDates: [
    //   { start: "2014-03-21 00:00:00", end: "2014-03-28 00:00:00" },
    //   {
    //     start: "2013-10-26 00:00:00",
    //     end: "2013-10-28 00:00:00",
    //     repeat: "weekly",
    //   },
    //   {
    //     start: "2013-03-29 20:00:00",
    //     end: "2013-03-30 07:00:00",
    //     repeat: "daily",
    //   },
    // ],
  });

  const getCrewMemberName = (crewMemberId) => {
    const crewData = lookUp?.crewMembers;
    if (crewData[crewMemberId]) {
      return crewData[crewMemberId].name;
    } else {
      return "Crew member not found";
    }
  };
  const getServiceName = (serviceId) => {
    const servicesData = lookUp?.services;
    if (servicesData[serviceId]) {
      return servicesData[serviceId].serviceName;
    } else {
      return "Service not found";
    }
  };
  const getEquipmentName = (crewEquipmentId) => {
    const equipments = lookUp?.equipments;
    if (equipments[crewEquipmentId]) {
      return equipments[crewEquipmentId].type;
    } else {
      return "Equipment not found";
    }
  };

  const transformDataForTimeline = (lookup) => {
    const crewMemberDayWise = lookup.crewMemberDayWise;
    const timelineData = [];

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      let DayPolygons;
      if (crewId == 0) {
        DayPolygons = crewData.day.filter((dayData) => dayData.day == 0);
      } else if(selectedDay == "All") {
        DayPolygons = crewData.day
      } else {
        DayPolygons = crewData.day.filter(
          (dayData) => dayData.day == selectedDay
        );
      }
      DayPolygons.forEach((dayData) => {
        const polygons = dayData.polygon;

        polygons.forEach((polygonId, index) => {
          const polygon = lookup.polygons[polygonId];
          const crewMemberName = getCrewMemberName(polygon.crewMemberId);
          const serviceName = getServiceName(polygon?.serviceId).replace(
            /\s/g,
            "_"
          );
          const crewMemberId = polygon.crewMemberId;
          const equipmentName = getEquipmentName(polygon.crewEquipmentId);

          const task = {
            id: polygonId,
            content: `${getFirstLetter(serviceName)} - ${index + 1}`,
            start: convertTimestampToFormattedDate(polygon.arrivalTime),
            end: convertTimestampToFormattedDate(polygon.departureTime),
            group: `${crewMemberId}`,
            title: `<div>
          <div class="individualContainer">
          <div>Crew Member : ${crewMemberName}</div>
          <div>Service Name : ${serviceName.replace("_", " ")}</div>
          <div>Equipment Name : ${equipmentName.replace("_", " ")}</div>
          <div>Start Time : ${convertTimestampToFormattedDate(
            polygon.arrivalTime
          )}</div>
          <div>End Time : ${convertTimestampToFormattedDate(
            polygon.departureTime
          )}</div>
          </div>
          </div>`,
            className: `${serviceName}`, // You can define custom CSS classes here
          };

          timelineData.push(task);
        });
      });
    }
    return timelineData;
  };

  const transformGroupDataForTimeline = (lookup) => {
    const crewMembers = lookup.crewMembers;
    const groupData = [];

    for (const crewMemberId in crewMembers) {
      const crewMemberData = crewMembers[crewMemberId];

      const group = {
        id: crewMemberId,
        content: `${crewMemberData?.name}`,
        value: parseInt(crewMemberId),
        showNested: false,
        // className: 'timeline-event', // You can define custom CSS classes here
      };
      groupData.push(group);
    }
    return groupData;
  };

  const findMinMaxTimeFromLookup = (lookup) => {
    let minStartTime = null;
    let maxEndTime = null;
    const crewMemberDayWise = lookup.crewMemberDayWise;

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      let DayPolygons;
      if (selectedDay == "All") {
        DayPolygons = crewData.day
      } else {
        DayPolygons = crewData.day.filter(
          (dayData) => dayData.day == selectedDay
        );
      }
      DayPolygons.forEach((dayData) => {
        const polygons = dayData.polygon;

        polygons.forEach((polygonId) => {
          const polygon = lookup.polygons[polygonId];
          const startDate = convertTimestampToFormattedDate(
            polygon.arrivalTime
          );
          const endDate = convertTimestampToFormattedDate(
            polygon.departureTime
          );
          if (!minStartTime || startDate < minStartTime) {
            minStartTime = startDate;
          }
          if (!maxEndTime || endDate > maxEndTime) {
            maxEndTime = endDate;
          }
        });
      });
    }

    return { minStartTime, maxEndTime };
  };
  // const itemsData = useMemo(() => transformDataForTimeline({ ...lookUp }), [lookUp, selectedDay]);
  // const groupData = useMemo(() => transformGroupDataForTimeline({ ...lookUp }), [lookUp,selectedDay]);

  useEffect(() => {
    if (lookUp) {
      let lookupData = { ...lookUp };
      const timelineData = transformDataForTimeline(lookupData);
      setItemsData(timelineData);
      const groupData = transformGroupDataForTimeline(lookupData);
      setGroupData(groupData);
      const bounds = findMinMaxTimeFromLookup(lookupData);
      const chartStart = new Date(bounds.minStartTime);
      const chartEnd = new Date(bounds.maxEndTime);
      const adjustedTimes = convertStartAndEndTimes(chartStart, chartEnd);
      const clusterOpts = {
        start: adjustedTimes.start,
        end: adjustedTimes.end,
        min: adjustedTimes.start + 1,
        max: adjustedTimes.end + 1,
      };
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...clusterOpts,
      }));
    }
  }, [lookUp, selectedDay]);

  useEffect(() => {
    if (itemsData && groupData) {
      const items = new DataSet(itemsData);
      var timeline = new Timeline(
        timelineRef.current,
        itemsData,
        groupData,
        options
      );

      timeline.on("itemover", function (params) {
        if (params.item) {
          const item = items.get(params.item);
          onBarHover(item);
        }
      });

      timeline.on("select", (properties) => {
        if (properties.items.length > 0) {
          const selectedIds = properties.items;
          const polygon = lookUp.polygons[selectedIds[0]];
          const modifiedPolygon = {
            ...polygon,
            pathId: selectedIds[0],
            equipmentName: getEquipmentName(polygon.crewEquipmentId),
            serviceName: getServiceName(polygon.serviceId),
          };
          dispatch(setSelectedPolygon(modifiedPolygon));
        } else {
          dispatch(setSelectedPolygon(null));
        }
      });

      timeline.on("itemout", function () {
        onBarLeave(null);
      });

      return () => {
        if (timeline) {
          timeline.off("itemover");
          timeline.destroy();
        }
      };
    }
  }, [itemsData, groupData]);

  return (
    <div>
      <div ref={timelineRef} />
    </div>
  );
};

export default MapTimeline;
