import React from "react";
import { useSelector } from "react-redux";
import { DeckGlOverlay } from "./deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";
import { getServiceColorRgb } from "../../utils/common";

function getDeckGlLayers(
  data,
  filteredGeoJson,
  hoveredGeoJson,
  handleClickedPolygon
) {
  if (!data) return [];

  return [
    new GeoJsonLayer({
      id: "geojson",
      data: data,
      stroked: true,
      filled: true,
      getLineWidth:
        (filteredGeoJson && filteredGeoJson.features.length > 0) ||
        (hoveredGeoJson && Object.keys(hoveredGeoJson).length > 0)
          ? 0.1
          : 0.2,
      opacity:
        (filteredGeoJson && filteredGeoJson.features.length > 0) ||
        (hoveredGeoJson && Object.keys(hoveredGeoJson).length > 0)
          ? 0.01
          : 1,
      getFillColor: (f) => {
        return f?.properties?.serviceType == "obstacle"
          ? [0, 0, 0, 0]
          : getServiceColorRgb(f?.properties?.serviceType);
      },
      getLineColor: (f) => getServiceColorRgb(f?.properties?.serviceType),
      lineWidthMinPixels: 2,
      pickable: true,
      onClick:(info) => handleClickedPolygon(info),
      // Callback when the pointer enters or leaves an object
    }),
  ];
}

const InitialServiceLayers = ({
  hoveredGeoJson,
  filteredGeoJson,
  handleClickedPolygon
}) => {
  const {
    global: { geoJsonFilteredData, showObstacles },
  } = useSelector((state) => state);
  const filteredData = showObstacles
    ? geoJsonFilteredData
    : geoJsonFilteredData?.features?.filter(
        (feature) => feature?.properties?.serviceType !== "obstacle"
      );
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(
        filteredData,
        filteredGeoJson,
        hoveredGeoJson,
        handleClickedPolygon
      )}
    />
  );
};

export default InitialServiceLayers;
