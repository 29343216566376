import { Box, Button, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { handleNextStep, setLocationName, setStepsLabels } from "../../../slices/stepperFormSlice";
import { useNavigate } from "react-router-dom";
import { useCreateOrderMutation } from "../../../services/OpenApi";
import { toast } from "react-toastify";

const SiteDetailsForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.global.user);
  const locationName = useSelector((state) => state.stepperForm.locationName);
  const locationPinCoordinate = useSelector((state) => state.stepperForm.locationPinCoordinate);
  console.log(locationPinCoordinate)
  const [createOrder, { isLoading }] = useCreateOrderMutation();
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const response = await createOrder({
        orgName: user?.organization,
        content: {
          emailId: user.email,
          locationCoordinates: {
            lat: locationPinCoordinate.lat,
            lon: locationPinCoordinate.lng
          },
          ...data,
        },
      });
      if (response) {
        navigate(`/parcel/${response?.data?.data.id}`);
        toast.success("Site details added", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        dispatch(handleNextStep());
      }
    } catch (err) {
      console.log("error => ", err);
    }
  };

  const handleAddressChange = (e) => {
    dispatch(setLocationName(e.target.value));
    dispatch(
      setStepsLabels({ id: 1, description: e.target.value })
    );
  };

  return (
    <div className="StepsFormContainer">
      <h3 className="StepsFormContainerHeading">Your Address</h3>
      <Box
        component="form"
        noValidate
        onSubmit={handleFormSubmit(onSubmit)}
        sx={{ mt: 1 }}
      >
        <TextField
          size="small"
          fullWidth
          required
          id="companyName"
          label="Company Name"
          name="companyName"
          margin="normal"
          {...register("companyName", {
            required: "Company name is required",
          })}
          error={!!errors.companyName}
          helperText={errors.companyName ? errors.companyName.message : ""}
        />
        <TextField
          size="small"
          fullWidth
          required
          id="siteName"
          label="Site Name"
          name="siteName"
          margin="normal"
          {...register("siteName", {
            required: "Site name is required",
          })}
          error={!!errors.siteName}
          helperText={errors.siteName ? errors.siteName.message : ""}
        />
        <TextField
          size="small"
          fullWidth
          required
          id="address"
          label="Address"
          name="address"
          margin="normal"
          {...register("address", {
            required: "Address is required",
            onChange: handleAddressChange
          })}
          value={locationName}
          error={!!errors.address}
          helperText={errors.address ? errors.address.message : ""}
        />
        <TextField
          size="small"
          fullWidth
          id="description"
          label="Description"
          name="description"
          margin="normal"
        />
        <Button
          type="submit"
          fullWidth
          //   disabled={isLoading}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {/* {isLoading ? <CircularProgress size={24} /> : "Log In"} */}
          Confirm
        </Button>
      </Box>
    </div>
  );
};

export default SiteDetailsForm;
