import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  convertTime,
  convertTimestampToFormattedDate,
  formatDistance,
} from "../../utils/common";
import { MdArrowBack } from "react-icons/md";
import "./CrewMemberDetails.css";
import { CircularProgress } from "@mui/material";
import CrewMemberDetailsDayWise from "./CrewMemberDetailsDayWise";
import { RotateLoader } from "react-spinners";

const CrewMemberDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [crewMember, setCrewMember] = useState(null);
  const [crewMemberSkillsUsed, setCrewMemberSkillsUsed] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [crewMemberDayWise, setCrewMemberDayWise] = useState(null);
  const [pathIds, setPathIds] = useState(null);
  const [serviceTimeData, setServiceTimeData] = useState(null);
  const lookUp = useSelector((state) => state.global.lookUpData);
  const mapData = useSelector((state) => state.global.mapData);
  useEffect(() => {
    if (lookUp && params) {
      setCrewMember(lookUp.crewMembers[params.id]);
      let key = params.id;
      let value = lookUp.crewMemberDayWise[key];
      let result = {
        [key]: value,
      };

      setCrewMemberDayWise(result);
      const crewMemberServiceWiseData =
        lookUp.crewMemberDayWiseService[params.id];
      setCrewMemberSkillsUsed(getServiceNames(crewMemberServiceWiseData));
      setPathIds(lookUp.crewMemberWise[params.id].polygons);
    }
  }, [lookUp, params]);

  const getServiceName = (serviceId) => {
    const servicesData = lookUp?.services;
    if (servicesData[serviceId]) {
      return servicesData[serviceId].serviceName;
    } else {
      return "Service not found";
    }
  };
  const transformDataForTimelineSummary = (lookup) => {
    const crewMemberDayWise = lookup.crewMemberDayWise;
    const timelineData = [];

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      crewData.day.forEach((dayData) => {
        const polygons = dayData.polygon;
        let currentServiceName = null;
        let currentServiceId = null;
        let currentStartTime = null;
        let currentEndTime = null;
        let currentCrewMemberId = null;
        let currentEquipment = null;
        let currentPolygonId = null;

        polygons.forEach((polygonId, index) => {
          const polygon = lookup.polygons[polygonId];

          if (currentServiceName === null) {
            // This is the first polygon

            currentServiceName = getServiceName(polygon.serviceId);
            currentServiceId = polygon.serviceId;
            currentStartTime = polygon.arrivalTime;
            currentCrewMemberId = polygon.crewMemberId;
            currentEquipment = polygon.equipmentName;
            currentPolygonId = polygonId;
          } else if (currentServiceName === polygon.serviceName) {
            // Same service, update the end time
            currentEndTime = polygon.departureTime;
          } else {
            // Service name changed, push the previous task and start a new one
            pushTask(
              currentStartTime,
              currentEndTime,
              currentCrewMemberId,
              currentEquipment
            );

            currentServiceName = polygon.serviceName;
            currentStartTime = polygon.arrivalTime;
            currentServiceId = polygon.serviceId;
            currentCrewMemberId = polygon.crewMemberId;
            currentEquipment = polygon.equipmentName;
            currentPolygonId = polygonId;
          }

          // Always update the end time to the current polygon's departure time
          currentEndTime = polygon.departureTime;

          // If it's the last polygon, push the task
          if (index === polygons.length - 1) {
            pushTask(
              currentStartTime,
              currentEndTime,
              currentCrewMemberId,
              currentEquipment
            );
          }
        });
      });

      function pushTask(startTime, endTime, crewMemberId, equipmentName) {
        const task = {
          id: `${crewMemberId}`,
          equipment: equipmentName,
          start: convertTimestampToFormattedDate(startTime),
          end: convertTimestampToFormattedDate(endTime),
        };

        if (crewMemberId == crewMember?.crewMemberId) {
          timelineData.push(task);
        }
      }
    }

    return timelineData;
  };

  function getServiceNames(data) {
    // Initialize an empty array to hold the service names
    const serviceNames = [];

    // Iterate over the day array
    for (const day of data.day) {
      // Check if services exist
      if (day.services && day.services.length) {
        // Iterate over the services array and push the serviceName into serviceNames array
        for (const service of day.services) {
          if (!serviceNames.includes(service.serviceName)) {
            serviceNames.push(service.serviceName);
          }
        }
      }
    }

    return serviceNames;
  }

  useEffect(() => {
    if (lookUp && crewMember) {
      const serviceTimeData = transformDataForTimelineSummary(lookUp);
      setServiceTimeData(serviceTimeData);
    }
  }, [lookUp, crewMember]);

  const printDocument = () => {
    window.print();
  };

  if (!(crewMember && mapData))
    return (
      <div className="spinner">
        <RotateLoader color="#21452e" size={20} margin={5} />
      </div>
    );

  return (
    <div className="mainCrewMemberContainer">
      <h1 className="summaryHeading">Crew Member Summary</h1>
      <div className="mainCrewMemberHeadWrapper">
        <div className="headLeft">
          <div className="backArrow" onClick={() => navigate(-1)}>
            <MdArrowBack fontSize={25} />
          </div>
          <div>
            <h1>
              {crewMember.title} ({crewMember.name}) - {params.id}
            </h1>
          </div>
        </div>
        <div>
          <button className="commonButton" onClick={printDocument}>
            {isLoading ? <CircularProgress color="inherit" /> : "Print"}
          </button>
        </div>
      </div>
      <div className="wrapper">
        <div className="summaryAndMapContainer">
          <div className="summaryContainerAll">
            <div className="summaryDetailsAll">
              <div className="flexContainer">
                <div className="itemTitle">Employee </div>
                <div className="itemContent">{crewMember.name}</div>
              </div>
              <div className="flexContainer">
                <div className="itemTitle">Employee Id </div>
                <div className="itemContent">{params.id}</div>
              </div>
              <div className="flexContainer">
                <div className="itemTitle">Crew Id </div>
                <div className="itemContent">{crewMember.crewId}</div>
              </div>
              <div className="flexContainer">
                <div className="itemTitle">Work Time </div>
                <div className="itemContent">
                  {convertTime(crewMember.totalAssignTimeOfWork)}
                </div>
              </div>
              <div className="flexContainer">
                <div className="itemTitle">Travel Time </div>
                <div className="itemContent">
                  {convertTime(crewMember.totalAssignTravelTime)}
                </div>
              </div>
              <div className="flexContainer">
                <div className="itemTitle">Travel Distance </div>
                <div className="itemContent">
                  {formatDistance(crewMember.totalAssignTravelDistance)}
                </div>
              </div>
              <div className="flexContainer">
                <div className="itemTitle">Skills </div>
                <div className="itemContent">
                  {crewMember.skills &&
                    crewMember.skills.map((skill, index) => (
                      <span key={index}>
                        {skill}
                        {index < crewMember.skills.length - 1 ? ", " : ""}
                      </span>
                    ))}
                </div>
              </div>
              <div className="flexContainer">
                <div className="itemTitle">Skills Used </div>
                <div className="itemContent">
                  {crewMemberSkillsUsed &&
                    crewMemberSkillsUsed.map((skill, index) => (
                      <span key={index}>
                        {skill.replace("_", " ")}
                        {index < crewMemberSkillsUsed.length - 1 ? ", " : ""}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CrewMemberDetailsDayWise
          crewMemberDayWise={crewMemberDayWise}
          serviceTimeData={serviceTimeData}
          crewMember={crewMember}
        />
      </div>
    </div>
  );
};

export default CrewMemberDetails;
