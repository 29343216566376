import React, { useEffect, useState } from "react";
import MapScreen from "./MapScreen/MapScreen";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnimationData,
  setInitialCrewMembers,
  setLandingPageMap,
  setLookUpData,
  setMapData,
  setSelectedCrewMembersId,
} from "../slices/globalSlice";
import { toast } from "react-toastify";
import { RotateLoader } from "react-spinners";
import LocationGroupModal, {
  fetchS3Data,
} from "../Modals/LocationGroupModal/LocationGroupModal";
import { useLocation, useParams } from "react-router-dom";
import {
  setIsLocationSet,
  setSelectedLocation,
  setSelectedServiceType,
  setWorkerPlanFolderData,
} from "../slices/workPlanSlice";
import { useGetWorkPlansMutation } from "../services/Properties";
import {
  ShuffleJson,
  assignCrewMemberColors,
  assignCrewMemberColorsToAnimation,
  calculateServicesTotal,
  calculateTotalAssignmentsForCrewMember,
} from "../utils/common";

const MainScreen = () => {
  const dispatch = useDispatch();
  const locationQuery = useLocation();
  const user = useSelector((state) => state.global.user);
  const isLocationSet = useSelector((state) => state.workPlan.isLocationSet);
  const [isModalOpen, setIsModalOpen] = useState(!isLocationSet);
  const lookUpData = useSelector((state) => state.global.lookUpData);
  const initialCrewMembers = useSelector(
    (state) => state.global.initialCrewMembers
  );
  const [getWorkPlans, { isLoading }] = useGetWorkPlansMutation();

  useEffect(() => {
    if (locationQuery && user && Object.keys(user).length > 0) {
      const fetchWorkPlans = async () => {
        const location = new URLSearchParams(locationQuery.search).get(
          "location"
        );
        const workFolder = new URLSearchParams(locationQuery.search).get(
          "workFolder"
        );
        const data = {
          organization: user?.organization,
          locationGroup: location,
        };
        try {
          const workDataFolder = await getWorkPlans(data).unwrap();
          dispatch(setWorkerPlanFolderData(workDataFolder?.results));
          const currentFolder = workDataFolder?.results?.filter(
            (item) => item.key == workFolder
          );
          const MapScreenGeoJson = currentFolder[0]?.files?.filter(
            (item) => item.file_type == "site_plan_map"
          );
          const LookupFile = currentFolder[0]?.files?.filter(
            (item) => item.file_type == "planner_output_lookup"
          );

          const animationFile = currentFolder[0]?.files?.filter(
            (item) => item.file_type == "planner_animation"
          );

          if (animationFile?.length > 0) {
            fetchS3Data(animationFile[0]?.file_s3_url).then((data) => {
              const animatedData = assignCrewMemberColorsToAnimation(data);
              if (animatedData.hasOwnProperty("0")) {
                delete animatedData["0"];
              }
              dispatch(setAnimationData(animatedData));
            });
          }

          dispatch(setSelectedLocation(location));
          dispatch(setSelectedServiceType(workFolder));
          fetchS3Data(MapScreenGeoJson[0]?.file_s3_url)
            .then((data) => {
              ShuffleJson(data);
              dispatch(setMapData(data));
              dispatch(setLandingPageMap(data));
            })
            .catch((error) => {
              toast.error(error?.error);
            });
          fetchS3Data(LookupFile[0]?.file_s3_url)
            .then((data) => {
              const fileData = data?.lookUpOutput ? data?.lookUpOutput : data;
              const final = calculateTotalAssignmentsForCrewMember(fileData);
              const withServices = calculateServicesTotal(final);
              const crewColors = assignCrewMemberColors(withServices);
              dispatch(setLookUpData(crewColors));
              dispatch(setInitialCrewMembers(withServices?.crewMemberDayWise));
            })
            .catch((error) => {
              toast.error("Uploaded Lookup Files is having wrong format", {
                autoClose: 2000,
                hideProgressBar: true,
              });
            });
        } catch (error) {
          toast.error(error?.error);
        }
      };
      fetchWorkPlans();
    }
  }, [locationQuery, user]);

  useEffect(() => {
    if (lookUpData) {
      dispatch(
        setSelectedCrewMembersId(
          Object.keys(lookUpData?.crewMemberDayWise).reduce(
            (acc, crewMemberId) => {
              acc[crewMemberId] = false;
              return acc;
            },
            {}
          )
        )
      );
    }
  }, [lookUpData]);
  useEffect(() => {
    if (lookUpData?.crewMemberDayWise) {
      dispatch(setInitialCrewMembers(lookUpData.crewMemberDayWise));
    }
  }, [lookUpData]);

  return (
    <>
      {initialCrewMembers ? (
      <MapScreen initialCrewMembers={initialCrewMembers} />) : (
      <div className="spinner">
        <RotateLoader color="#21452e" size={20} margin={5} />
      </div>
      )}
    </>
  );
};

export default MainScreen;
