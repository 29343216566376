import { isJwtExpired } from "jwt-check-expiration";
import jwtDecode from "jwt-decode";
import _ from "lodash";

export const stepperStatus = {
  SITE: 1,
  PARCEL: 2,
  PARKING: 3,
  SERVICE: 4,
  CREW: 5,
};

export const checkIfLogin = () => {
  const token = localStorage.getItem("accessToken");
  if (token && isJwtExpired(token)) {
    return false;
  }
  return !!token;
};

export const getTokenExpiry = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    if (decodedToken && decodedToken.exp) {
      // Expiry time is in seconds, convert to milliseconds
      const expiryTimeInMillis = decodedToken.exp * 1000;
      return new Date(expiryTimeInMillis);
    } else {
      // If token or expiry time is missing, return null
      return null;
    }
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};

export const ShuffleJson = (file) => {
  // Separate the features based on geometry type
  let lineStringFeatures = [];
  let otherFeatures = [];

  file.features.forEach((feature) => {
    if (feature.geometry.type === "LineString") {
      lineStringFeatures.push(feature);
    } else {
      otherFeatures.push(feature);
    }
  });

  // Concatenate the features, placing LineString features at the end
  file.features = otherFeatures.concat(lineStringFeatures);
};

export function convertTimestampToFormattedDate(timestamp) {
  const date = new Date(timestamp);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

export function convertTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);
  return `${hours}h ${mins}m`;
}

export function formatDistance(feet) {
  return (feet / 5280).toFixed(2) + "mi";
}

export function calculateTotalTime(start, end) {
  const startTime = new Date(start);
  const endTime = new Date(end);
  const timeDifference = endTime - startTime;

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  // Return the total time as an object
  return `${hours} hrs ${minutes} min ${seconds} sec`;
}

export function convertStartAndEndTimes(start, end) {
  start.setHours(start.getHours() - 0.5, 0, 1, 0);
  end.setHours(start.getHours() + 9, 0, 1, 0);

  // Adjust end time to be one hour more than the original
  // end.setHours(end.getHours() + 1, end.getMinutes(), end.getSeconds(), end.getMilliseconds());
  return { start, end };
}

export function truncateString(str, num) {
  if (str.length > num) {
    return str.substring(0, num) + "...";
  } else {
    return str;
  }
}

export function getEquipmentTimeFormatted(dateString) {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // Convert to 12-hour format
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero if minutes are less than 10
  return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
}

export function calculateTotalAssignmentsForCrewMember(data) {
  const updatedData = _.cloneDeep(data);

  for (const crewId in updatedData?.crewMemberDayWise) {
    let crewMember = updatedData.crewMemberDayWise[crewId];
    let totalAssignTravelTime = 0;
    let totalAssignTimeOfWork = 0;
    let totalAssignTravelDistance = 0;

    for (const day of crewMember.day) {
      for (const polygonId of day.polygon) {
        const polygon = updatedData.polygons[polygonId];
        if (polygon && polygon.crewMemberId === parseInt(crewId)) {
          totalAssignTravelTime += polygon.travelTime;
          totalAssignTimeOfWork += polygon.duration;
          totalAssignTravelDistance += polygon.travelDistance;
        }
      }
    }

    // Update crewMembers with the calculated values
    updatedData.crewMembers[crewId].totalAssignTravelTime =
      totalAssignTravelTime;
    updatedData.crewMembers[crewId].totalAssignTimeOfWork =
      totalAssignTimeOfWork;
    updatedData.crewMembers[crewId].totalAssignTravelDistance =
      totalAssignTravelDistance;
  }

  return updatedData;
}

export function calculateServicesTotal(data) {
  const serviceTotals = {};

  // Calculate total travel time and total area for each service
  for (const polygonId in data?.polygons) {
    const polygon = data.polygons[polygonId];
    const serviceId = polygon?.serviceId;
    const travelTime = polygon?.duration;
    const measurement = polygon?.measurement;

    if (!serviceTotals[serviceId]) {
      serviceTotals[serviceId] = {
        totalTime: 0,
        totalArea: 0,
      };
    }

    serviceTotals[serviceId].totalTime += travelTime;
    serviceTotals[serviceId].totalArea += measurement;
  }

  // Add totalTime and totalArea to the corresponding service
  for (const serviceId in serviceTotals) {
    if (data.services[serviceId]) {
      data.services[serviceId].totalTime = serviceTotals[serviceId].totalTime;
      data.services[serviceId].totalArea = serviceTotals[serviceId].totalArea;
    }
  }
  return data;
}

export function hexToRgb(hex) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
}

export function getServiceColor(serviceName) {
  switch (serviceName) {
    case "Turf":
      return "#c7e9c0";
    case "Soft Edge":
      return "#d69253";
    case "String Trimmer":
      return "#d69253";
    case "Hard Edge":
      return "#915c30";
    case "Mulch Beds":
      return "#e8d966";
    case "Rock Beds":
      return "#575c63";
    case "Hedge":
      return "#197901";
    case "Cleanup Blowing":
      return "#72b4da";
    case "Flower Beds":
      return "#b813ce";
    case "obstacle":
      return "#141414";
    case "Water Body":
      return "#46d9fd";
    default:
      return "black";
  }
}
export function getServiceColorRgb(serviceName) {
  switch (serviceName) {
    case "Turf":
      return [199, 233, 192];
    case "Large Turf":
      return [108, 181, 93];
    case "Small Turf":
      return [199, 233, 192];
    case "Soft Edge":
      return [214, 146, 83];
    case "Hard Edge":
      return [145, 92, 48];
    case "Mulch Beds":
      return [232, 217, 102];
    case "Rock Beds":
      return [87, 92, 99];
    case "Hedge":
      return [25, 121, 1];
    case "Cleanup Blowing":
      return [114, 180, 218];
    case "String Trimmer":
      return [214, 146, 83];
    case "Flower Beds":
      return [184, 19, 206];
    case "obstacle":
      return [20, 20, 20];
    case "Water Body":
      return [70, 217, 253];
    default:
      return [0, 0, 0];
  }
}

export const serviceColors = {
  rockBeds: "#575c63",
  mulchBeds: "#e8d966",
  turf: "#c7e9c0",
  smallTurf: "#6cb55d",
  largeTurf: "#c7e9c0",
  hedge: "#197901",
  cleanupBlowing: "#72b4da",
  stringTrimmer: "#d69253",
  lineSmallTurf: "#a1d99b",
  lineLargeTurf: "#c9e7c3",
  lineMulchBeds: "#beac2d",
  lineHedge: "#0b7000",
  lineRockBeds: "#5c5c5c",
  lineHardEdge: "#915c30",
  lineSoftEdge: "#d69253",
  lineStringTrimmer: "#d69253",
  lineObstacle: "#141414",
};

export const crewMemberColors = [
  "#0040ff",
  "#ff4dc4",
  "#b30000",
  "#08a8e7",
  "#ffb900",
  "#cf9270",
  "#ffb339",
  "#b5515b",
  "#d9ccff",
  "#2f7589",
  "#6b2600",
  "#ff8800",
  "#40b2c6",
  "#8898c0",
  "#990b0b",
];

export const getCrewMemberColor = (key, data) => {
  return data[key]?.crewMemberColor || null;
};

function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

export function assignCrewMemberColors(data) {
  Object.keys(data.crewMemberDayWise).forEach((memberId) => {
    const hash = hashCode(memberId);
    const colorIndex = hash % crewMemberColors.length;
    data.crewMemberDayWise[memberId].crewMemberColor =
      crewMemberColors[colorIndex];
  });
  return data;
}

export function assignCrewMemberColorsToAnimation(data) {
  const updatedData = _.cloneDeep(data);
  Object.keys(updatedData).forEach((memberId) => {
    const hash = hashCode(memberId);
    const colorIndex = hash % crewMemberColors.length;
    updatedData[memberId].crewMemberColor = crewMemberColors[colorIndex];
  });
  return updatedData;
}

export function extractFolderId(url) {
  const pattern = /\/folders\/([a-zA-Z0-9_-]+)/;
  const match = url?.match(pattern);
  if (match && match[1]) {
    return match[1];
  } else {
    return null; // or an appropriate error/indicator
  }
}

export const getCrewMemberName = (crewMemberId, lookUp) => {
  const crewData = lookUp?.crewMembers;
  if (crewData[crewMemberId]) {
    return crewData[crewMemberId].name;
  } else {
    return "Crew member not found";
  }
};

export const getServiceName = (serviceId, lookUp) => {
  const servicesData = lookUp?.services;
  if (servicesData[serviceId]) {
    return servicesData[serviceId].serviceName;
  } else {
    return "Service not found";
  }
};
export const getEquipmentName = (crewEquipmentId, lookUp) => {
  const equipments = lookUp?.equipments;
  if (equipments[crewEquipmentId]) {
    return equipments[crewEquipmentId].type;
  } else {
    return "Equipment not found";
  }
};

export const getSkillName = (skillId, skillsArray) => {
  const skill = skillsArray.filter((skill) => {
    if (skill.skillId === skillId) {
      return skill;
    }
  });
  return skill[0]?.skillName;
};

export function getEquipmentId(equipmentId, equipmentsArray) {
  const equipment = equipmentsArray.filter((equipment) => {
    if (equipment.equipmentId === equipmentId) {
      return equipment;
    }
  });
  return equipment[0]?.equipment;
}
export function getServiceNameForCrewCreation(serviceId, ServicesArray) {
  const service = ServicesArray.filter((service) => {
    if (service.id === serviceId) {
      return service;
    }
  });
  return service[0]?.description;
}

export function getFirstLetter(str) {
  const words = str.split("_");

  let FirstLetters = "";

  for (let i = 0; i < words.length; i++) {
    FirstLetters += words[i][0];
  }

  return FirstLetters;
}

function forCurrentHoveredPolygon(
  hoveredPolygon,
  lookUpData,
  nextPolygon,
  parkingId
) {
  const routePlan = [];
  const wayPointsGeoJson = [];
  if (hoveredPolygon?.fromParking) {
    if (hoveredPolygon?.wayPoints && hoveredPolygon?.wayPoints?.length > 0) {
      hoveredPolygon?.wayPoints.map((wayPoint, index) => {
        // Using map here as each waypoint produces one feature
        wayPointsGeoJson.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [parseFloat(wayPoint.lon), parseFloat(wayPoint.lat)],
          },
          properties: {},
        });
      });
      routePlan.push({
        entryPoint: lookUpData?.parkings[parkingId]?.point,
        exitPoint: hoveredPolygon?.wayPoints[0],
      });
      for (let i = 0; i < hoveredPolygon?.wayPoints?.length - 2; i++) {
        const wayPoint = hoveredPolygon?.wayPoints[i];
        const nextWayPoint = hoveredPolygon?.wayPoints[i + 1];
        routePlan.push({
          entryPoint: wayPoint,
          exitPoint: nextWayPoint,
        });
      }
      routePlan.push({
        entryPoint:
          hoveredPolygon.wayPoints[hoveredPolygon?.wayPoints.length - 1],
        exitPoint: hoveredPolygon.entryPoint,
      });
    } else {
      routePlan.push({
        entryPoint: lookUpData?.parkings[parkingId]?.point,
        exitPoint: hoveredPolygon.entryPoint,
      });
    }
  }

  if (hoveredPolygon?.toParking) {
    if (hoveredPolygon?.wayPoints && hoveredPolygon?.wayPoints?.length > 0) {
      routePlan.push({
        entryPoint: hoveredPolygon?.exitPoint,
        exitPoint: hoveredPolygon?.wayPoints[0],
      });
      for (let i = 0; i <= hoveredPolygon?.wayPoints.length - 2; i++) {
        const wayPoint = hoveredPolygon?.wayPoints[i];
        const nextWayPoint = hoveredPolygon?.wayPoints[i + 1];
        routePlan.push({
          entryPoint: wayPoint,
          exitPoint: nextWayPoint,
        });
      }
      routePlan.push({
        entryPoint:
          hoveredPolygon?.wayPoints[hoveredPolygon?.wayPoints?.length - 1],
        exitPoint: hoveredPolygon?.entryPoint,
      });
    }
    if (
      hoveredPolygon?.toParkingWayPoints &&
      hoveredPolygon?.toParkingWayPoints?.length > 0
    ) {
      hoveredPolygon?.toParkingWayPoints.map((wayPoint, index) => {
        // Using map here as each waypoint produces one feature
        wayPointsGeoJson.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [parseFloat(wayPoint.lon), parseFloat(wayPoint.lat)],
          },
          properties: {},
        });
      });
      routePlan.push({
        entryPoint: hoveredPolygon?.exitPoint,
        exitPoint: hoveredPolygon?.toParkingWayPoints[0],
      });
      for (let i = 0; i <= hoveredPolygon?.toParkingWayPoints.length - 2; i++) {
        const wayPoint = hoveredPolygon?.toParkingWayPoints[i];
        const nextWayPoint = hoveredPolygon?.toParkingWayPoints[i + 1];
        routePlan.push({
          entryPoint: wayPoint,
          exitPoint: nextWayPoint,
        });
      }
      routePlan.push({
        entryPoint:
          hoveredPolygon?.toParkingWayPoints[
            hoveredPolygon?.toParkingWayPoints?.length - 2
          ],
        exitPoint:
          hoveredPolygon?.toParkingWayPoints[
            hoveredPolygon?.toParkingWayPoints?.length - 1
          ],
      });
    } else {
      routePlan.push({
        entryPoint: hoveredPolygon?.exitPoint,
        exitPoint: lookUpData?.parkings[parkingId]?.point,
      });
    }
  } else if (nextPolygon?.wayPoints && nextPolygon?.wayPoints?.length > 0) {
    for (let i = 0; i < nextPolygon.wayPoints.length - 1; i++) {
      const wayPoint = nextPolygon.wayPoints[i];
      const nextWayPoint = nextPolygon.wayPoints[i + 1];
      if (wayPoint && nextWayPoint) {
        routePlan.push({
          entryPoint: wayPoint,
          exitPoint: nextWayPoint,
        });
      }
    }
    nextPolygon?.wayPoints.map((wayPoint, index) => {
      // Using map here as each waypoint produces one feature
      wayPointsGeoJson.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [parseFloat(wayPoint.lon), parseFloat(wayPoint.lat)],
        },
        properties: {},
      });
    });
  } else {
    if (hoveredPolygon && nextPolygon) {
      routePlan.push({
        entryPoint: hoveredPolygon?.exitPoint,
        exitPoint: nextPolygon?.entryPoint,
      });
    }
  }
  return { routePlan: routePlan, wayPointsGeoJson: wayPointsGeoJson };
}

export function getPreviousAndNextLineData(hoveredId, parkingId, lookUpData) {
  const hoveredPolygon = lookUpData?.polygons[hoveredId];
  const crewMember =
    lookUpData?.crewMemberDayWise[hoveredPolygon?.crewMemberId];
  let prevPolygonId = null;
  let nextPolygonId = null;
  crewMember?.day?.map((day) => {
    for (let i = 0; i < day?.polygon.length; i++) {
      if (day.polygon[i] === hoveredId) {
        if (i === 0) {
          nextPolygonId = day.polygon[i + 1];
        } else if (i === day.polygon.length - 1) {
          prevPolygonId = day.polygon[i - 1];
        } else {
          prevPolygonId = day.polygon[i - 1];
          nextPolygonId = day.polygon[i + 1];
        }
      }
    }
  });

  const prevPolygon = lookUpData?.polygons[prevPolygonId];
  const nextPolygon = lookUpData?.polygons[nextPolygonId];
  const prevConnectingRoute = forCurrentHoveredPolygon(
    prevPolygon,
    lookUpData,
    hoveredPolygon,
    parkingId
  );
  const nextConnectingRoute = forCurrentHoveredPolygon(
    hoveredPolygon,
    lookUpData,
    nextPolygon,
    parkingId
  );

  return {
    wayPointsGeoJson: [
      ...nextConnectingRoute.wayPointsGeoJson,
      ...prevConnectingRoute.wayPointsGeoJson,
    ],
    route: {
      nextConnectingRoute: [...nextConnectingRoute.routePlan],
      prevConnectingRoute: [...prevConnectingRoute.routePlan],
    },
  };
}

export const shapeToGeoJSON = (shape) => {
  const { google } = window;
  let geojson = null;

  if (shape instanceof google.maps.Marker) {
    geojson = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [shape.getPosition().lng(), shape.getPosition().lat()],
      },
      properties: {},
    };
  } else if (shape instanceof google.maps.Circle) {
    geojson = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [shape.getCenter().lng(), shape.getCenter().lat()],
      },
      properties: {
        radius: shape.getRadius(),
      },
    };
  } else if (shape instanceof google.maps.Polygon) {
    geojson = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: shape
          .getPaths()
          .getArray()
          .map((path) =>
            path.getArray().map((latlng) => [latlng.lng(), latlng.lat()])
          ),
      },
      properties: {
        area: calculatePolygonArea(shape),
      },
    };
  } else if (shape instanceof google.maps.Polyline) {
    geojson = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: shape
          .getPath()
          .getArray()
          .map((latlng) => [latlng.lng(), latlng.lat()]),
      },
      properties: {},
    };
  } else if (shape instanceof google.maps.Rectangle) {
    const bounds = shape.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    geojson = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [sw.lng(), sw.lat()],
            [ne.lng(), sw.lat()],
            [ne.lng(), ne.lat()],
            [sw.lng(), ne.lat()],
            [sw.lng(), sw.lat()],
          ],
        ],
      },
      properties: {},
    };
  }

  return geojson;
};

export const calculatePolygonArea = (polygon) => {
  const path = polygon.getPath();
  return window?.google?.maps?.geometry?.spherical?.computeArea(path);
};

export const geoJSONToShape = (geojson, map, addUpdateListener, readOnly) => {
  const { google } = window;
  const { type, geometry, properties } = geojson;
  let shape = null;
  const options = {
    map,
    editable: !readOnly,
    draggable: !readOnly,
    clickable: !readOnly,
  };

  switch (geometry.type) {
    case "Point":
      shape = new google.maps.Marker({
        position: new google.maps.LatLng(
          geometry.coordinates[1],
          geometry.coordinates[0]
        ),
        ...options,
      });
      break;
    case "LineString":
      shape = new google.maps.Polyline({
        path: geometry.coordinates.map(
          (coord) => new google.maps.LatLng(coord[1], coord[0])
        ),
        ...options,
      });
      break;
    case "Polygon":
      shape = new google.maps.Polygon({
        paths: geometry.coordinates.map((ring) =>
          ring.map((coord) => new google.maps.LatLng(coord[1], coord[0]))
        ),
        ...options,
      });
      !readOnly &&
        ["mouseup"].forEach((eventName) => addUpdateListener(eventName, shape));
      break;
    default:
      break;
  }

  return { type: geometry.type, geometry: shape };
};
