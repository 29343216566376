import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRegisterMutation } from "../../services/Auth";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { getCustomColor } from "../../utils/theme";

// TODO remove, this demo shouldn't need to reset the theme.
export default function Register() {
  const {
    register: registerEmail,
    handleSubmit: handleEmailSubmit,
    getValues: getValuesEmail,
    formState: { errors: errorsEmail },
  } = useForm();
  const {
    register: registerPassword,
    handleSubmit: handleFormSubmit,
    getValues,
    formState: { errors: errorsPassword },
  } = useForm();
  const blackShade1 = getCustomColor("blackShade1");
  const [showPasswords, setShowPasswords] = useState(false);
  const [showPasswordText1, setShowPasswordText1] = useState(false);
  const [showPasswordText2, setShowPasswordText2] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  const handleClickShowPassword1 = () => setShowPasswordText1((show) => !show);
  const handleClickShowPassword2 = () => setShowPasswordText2((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [register, { isLoading }] = useRegisterMutation();
  const onSubmit = async (data) => {
    const values = {
      username: getValuesEmail("email"),
      password1: data.password,
      password2: data.password,
    };
    try {
      const response = await register(values).unwrap();

      console.log("repsonse ==>>> ", response, getValuesEmail("email"), values);
      if (response) {
        setIsRegistered(true);
        toast.success("Verification link has been sent to your email", {
          autoClose: 1000,
          hideProgressBar: true,
        });
      }
    } catch (error) {
      toast.error(error?.data?.detail, {
        autoClose: 1000,
        hideProgressBar: true,
      });
    }
  };

  const onEmailSubmit = () => {
    setShowPasswords(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="authContainer"
      >
        <Typography
          component="h3"
          fontWeight="600"
          sx={{ color: blackShade1.main }}
          variant="h5"
        >
          Create your account
        </Typography>
        {!isRegistered ? (
          !showPasswords ? (
            <Box
              component="form"
              noValidate
              onSubmit={handleEmailSubmit(onEmailSubmit)}
              sx={{ mt: 1 }}
            >
              <Typography
                fontWeight={"700"}
                sx={{ color: blackShade1.light }}
                component="body1"
                variant="body1"
              >
                Email
              </Typography>
              <TextField
                fullWidth
                size="small"
                margin="dense"
                id="email"
                name="email"
                placeholder="Enter an email id to get started"
                inputProps={{
                  form: {
                    autocomplete: "off",
                  },
                }}
                autoFocus
                {...registerEmail("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Enter a valid email address",
                  },
                })}
                error={!!errorsEmail.email}
                helperText={errorsEmail.email ? errorsEmail.email.message : ""}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, boxShadow: "none" }}
              >
                Continue
              </Button>

              <Typography
                component="div"
                fontWeight="500"
                sx={{ color: blackShade1.main }}
                variant="div"
                className="createOne"
              >
                Already have an account?{" "}
                <Typography
                  component="span"
                  fontWeight="500"
                  sx={{ color: "primary.dark" }}
                  variant="span"
                  onClick={() => navigate("/login")}
                >
                  Login
                </Typography>
              </Typography>
            </Box>
          ) : (
            <>
              <Typography
                component="h3"
                sx={{ color: blackShade1.main }}
                marginTop="20px"
                variant="body2"
              >
                Set password for{" "}
                <Typography
                  component="span"
                  color="primary.dark"
                  variant="body2"
                >
                  {getValuesEmail("email")}
                </Typography>
              </Typography>
              <Typography
                component="h6"
                fontWeight="500"
                sx={{ cursor: "pointer" }}
                marginTop="10px"
                onClick={() => setShowPasswords(false)}
                color="primary.dark"
                variant="body2"
              >
                Change Email
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleFormSubmit(onSubmit)}
                sx={{ mt: 1 }}
              >
                <Typography
                  fontWeight={"600"}
                  sx={{ color: blackShade1.light }}
                  component="body1"
                  variant="body1"
                >
                  Enter Password
                </Typography>
                <TextField
                  margin="dense"
                  fullWidth
                  name="password"
                  size="small"
                  type={showPasswordText1 ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPasswordText1 ? (
                            <MdVisibilityOff />
                          ) : (
                            <MdVisibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  id="password"
                  {...registerPassword("password", {
                    required: "Password is required",
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        "Password must be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a special character",
                    },
                  })}
                  error={!!errorsPassword.password}
                  helperText={
                    errorsPassword.password
                      ? errorsPassword.password.message
                      : ""
                  }
                />
                <Typography
                  fontWeight={"600"}
                  sx={{ color: blackShade1.light }}
                  component="body1"
                  variant="body1"
                >
                  Confirm Password
                </Typography>
                <TextField
                  margin="dense"
                  fullWidth
                  name="confirmPassword"
                  size="small"
                  type={showPasswordText2 ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPasswordText2 ? (
                            <MdVisibilityOff />
                          ) : (
                            <MdVisibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="confirmPassword"
                  {...registerPassword("confirmPassword", {
                    required: "Password is required",
                    validate: (value) =>
                      value === getValues("password") || "Passwords must match",
                  })}
                  error={!!errorsPassword.confirmPassword}
                  helperText={
                    errorsPassword.confirmPassword
                      ? errorsPassword.confirmPassword.message
                      : ""
                  }
                />

                <Button
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                  variant="contained"
                  sx={{ mt: 3, mb: 2, boxShadow: "none" }}
                >
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Create Account"
                  )}
                </Button>
                <div className="createOne">
                  <Typography component="h3" marginTop="20px" variant="body2">
                    Already have an account?{" "}
                    <Typography
                      onClick={() => navigate("/login")}
                      sx={{ cursor: "pointer" }}
                      component="span"
                      color="primary.dark"
                      variant="body2"
                    >
                      Sign In
                    </Typography>
                  </Typography>
                </div>
              </Box>
            </>
          )
        ) : (
          <>
            <Typography
              component="h3"
              textAlign="center"
              marginTop="20px"
              variant="body2"
            >
              Verification email sent to{" "}
              <Typography component="span" color="primary.dark" variant="body2">
                {getValuesEmail("email")}
              </Typography>
            </Typography>
            <Typography
              component="h3"
              textAlign="center"
              marginTop="20px"
              variant="body2"
            >
              Please check your inbox and click on the verification link.
            </Typography>
          </>
        )}
      </Box>
      <div className="patternLeft">
        <img src="/images/patternsLeft.png" />
      </div>
      <div className="patternRight">
        <img src="/images/patternsRight.png" />
      </div>
    </>
  );
}
