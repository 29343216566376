import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useVerifyEmailMutation } from "../../services/Auth";

const VerifyEmail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const [isVerificationCompleted, setIsVerificationCompleted] = useState(false);

  const handleOnSubmit = async () => {
    try {
      console.log(params.id);
      const response = await verifyEmail({ key: params?.id }).unwrap();
      console.log(response);
      if (response) {
        setIsVerificationCompleted(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="authContainer"
      >
        {isVerificationCompleted ? (
          <>
            <Typography fontWeight={"600"} component="h3" variant="h5">
              Verification Complete!
            </Typography>
            <Button
              type="submit"
              fullWidth
              onClick={() => navigate("/login")}
              variant="contained"
              sx={{ mt: 4, mb: 2, boxShadow: "none" }}
            >
              Get started
            </Button>
          </>
        ) : (
          <>
            <Typography fontWeight={"600"} component="h3" variant="h5">
              Verify email
            </Typography>
            <Typography
              component="h3"
              textAlign="center"
              marginTop="20px"
              variant="body2"
            >
              Click the below button to Verify Email address
            </Typography>
            <Button
              type="submit"
              fullWidth
              disabled={isLoading}
              onClick={handleOnSubmit}
              variant="contained"
              sx={{ mt: 4, mb: 2, boxShadow: "none" }}
            >
              {isLoading ? <CircularProgress size={24} /> : "Verify Email"}
            </Button>
          </>
        )}
      </Box>
      <div className="patternLeft">
        <img src="/images/patternsLeft.png" />
      </div>
      <div className="patternRight">
        <img src="/images/patternsRight.png" />
      </div>
    </>
  );
};

export default VerifyEmail;

