import React from "react";
import { Card, CardContent, Typography, IconButton, Box } from "@mui/material";
import { MdDelete, MdEdit } from "react-icons/md";

const ParkingCard = ({ parking, onEdit, onDelete }) => {
  return (
    <div className="parkingCardContent">
      <div>
        <Typography variant="h6">{parking.parkingName}</Typography>
        <Typography variant="body2">Type - {parking.parkingType}</Typography>
        <div className="parkingAddress" variant="body2">{parking.address}</div>
      </div>
      <div>
        <IconButton color="primary" onClick={() => onEdit(parking.id)}>
          <MdEdit fontSize={14}/>
        </IconButton>
        <IconButton color="secondary" onClick={() => onDelete(parking.id)}>
          <MdDelete fontSize={14}/>
        </IconButton>
      </div>
    </div>
  );
};

export default ParkingCard;
