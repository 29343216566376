import React, { useEffect, useState, useMemo } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Source, Layer, Popup, Marker } from "react-map-gl";
import { useSelector } from "react-redux";
import Pin from "../../components/Mapbox/Pin";
import Circle from "../../components/Mapbox/Circle";
import ArrowHead from "../../components/Mapbox/ArrowHead";
import { calculateBounds, calculateZoom } from "../../utils/mapZoomCalculation";
import { routeGeneration } from "../../utils/routePlanFunction";

const CrewMemberMap = ({
  hoveredPolygon,
  crewMemberDayWise,
  mapData,
  polygonsData,
  currentPathIds,
  currentCrewMemberId,
}) => {
  const [selectedParking, setSelectedParking] = useState(null);
  const lookUpData = useSelector((state) => state.global.lookUpData);
  const [filteredGeoJson, setFilteredGeoJson] = useState(null);

  const [lineData, setLineData] = useState([]);
  const [map, setMap] = useState(null);
  const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/light-v11");
  const mapRef = React.createRef();
  const [viewport, setViewport] = React.useState({
    width: 800,
    height: 400,
    latitude: 40,
    longitude: -100,
    zoom: 3,
  });
  useEffect(() => {
    const filterGeoJson = () => {
      if (mapData && mapData?.features) {
        const filteredGeoJsonByCrewMember = {};

        const filteredFeatures = mapData.features.filter((feature) =>
          currentPathIds.includes(parseInt(feature.properties.pathId))
        );

        filteredGeoJsonByCrewMember[currentCrewMemberId] = {
          ...mapData,
          features: filteredFeatures,
        };

        setFilteredGeoJson(filteredGeoJsonByCrewMember);
      }
    };

    filterGeoJson();
  }, [currentCrewMemberId, mapData]);

  const handleMapLoad = (event) => {
    setMap(event.target);
  };

  const lookupGeoJSONByPathId = (geoJSON, targetPathId) => {
    if (geoJSON && geoJSON?.features) {
      for (const feature of geoJSON.features) {
        if (
          feature.properties &&
          feature.properties.pathId.toString() === targetPathId.toString()
        ) {
          const {
            geometry,
            geometry: { type },
            properties,
          } = feature;
          return { geometry, type, properties };
        }
      }
    }
    return null;
  };
  const extractEquallySpacedSubarray = (arr, m) => {
    const n = arr.length;

    // Check if m is valid
    if (m <= 0 || m >= n) {
      return [];
    }
    // Calculate the step size
    let stepSize = Math.floor(n / (m + 1));
    // Initialize the result array
    const result = [];

    // Extract equally spaced indices
    for (let i = 1; i <= m; i++) {
      const index = Math.floor(i * stepSize);
      result.push(arr[index]);
    }

    return result;
  };

  const calculateArrowPositions = (geometry, polygonId) => {
    const arrowPositions = [];
    const lineCoordinates = geometry?.coordinates;

    const minDistanceThreshold = 0.0000055; // Adjust this threshold based on your requirements
    for (let i = 1; i < lineCoordinates?.length; i++) {
      const [lon1, lat1] = lineCoordinates[i - 1];
      const [lon2, lat2] = lineCoordinates[i];

      // Calculate distance between consecutive coordinates
      const distance = Math.sqrt((lon2 - lon1) ** 2 + (lat2 - lat1) ** 2);
      // Place arrow only if distance exceeds the threshold
      if (distance > minDistanceThreshold) {
        // Calculate mid-point between consecutive coordinates
        const midPoint = [(lon1 + lon2) / 2.0, (lat1 + lat2) / 2.0];

        // Calculate angle of the line segment
        const angle = Math.atan2(lat2 - lat1, lon2 - lon1) * (180 / Math.PI);
        arrowPositions.push({ position: midPoint, angle });
      }
    }

    // time to cleanup some unnecessary arrows
    if (
      lineCoordinates?.length >= 3 && // complex polygons
      arrowPositions?.length > 2 && // More than 2 arrows
      arrowPositions?.length / lineCoordinates?.length >= 0.18 && // if the ratio of arrows is too high?
      arrowPositions?.length / lineCoordinates?.length < 1.0 // if the ratio of arrows is too high?
    ) {
      const finalArray = extractEquallySpacedSubarray(
        arrowPositions,
        Math.ceil(arrowPositions.length / 10)
      );

      return finalArray;
    }
    return arrowPositions;
  };

  const getEntryPointForPolygonId = (polygonId) => {
    const polygon = polygonsData[polygonId];

    if (polygon) {
      const entryPoint = polygon.entryPoint;
      return entryPoint;
    }

    return null;
  };


  const crewWiseMarkers = useMemo(
    () =>
      crewMemberDayWise &&
      Object.entries(crewMemberDayWise).map(([crewMemberId,crewMember]) => {
        const { day, crewMemberColor } = crewMember;

        return day.map((dayInfo) => {
          const { polygon } = dayInfo; // should have been polygons instead of polygon in key
          const color = crewMemberColor;
          return polygon.map((polygonId, i) => {
            const entryPoint = getEntryPointForPolygonId(polygonId);
            let gData = lookupGeoJSONByPathId(mapData, polygonId);
            let reverseGeometry = true;
            if (gData?.geometry?.coordinates) {
              if (
                entryPoint.lon === gData?.geometry?.coordinates[0][0] &&
                entryPoint.lat === gData?.geometry?.coordinates[0][1]
              ) {
                reverseGeometry = false;
              }
              if (reverseGeometry) {
                if (
                  gData &&
                  gData?.geometry &&
                  gData?.geometry?.coordinates &&
                  gData?.geometry?.coordinates?.length > 0
                ) {
                  gData = {
                    ...gData,
                    geometry: {
                      ...gData.geometry,
                      coordinates: [...gData?.geometry?.coordinates].reverse(),
                    },
                  };
                }
              }
            }
            if (gData?.geometry?.geometries) {
              if (
                entryPoint.lon ===
                  gData?.geometry?.geometries[0]?.coordinates[0][0] &&
                entryPoint.lat ===
                  gData?.geometry?.geometries[0]?.coordinates[0][1]
              ) {
                reverseGeometry = false;
              }
              if (reverseGeometry) {
                if (
                  gData &&
                  gData?.geometry &&
                  gData?.geometry?.geometries[0] &&
                  gData?.geometry?.geometries[0]?.coordinates?.length > 0
                ) {
                  const revisedGeometries = gData.geometry.geometries.map(
                    (geometry) => {
                      // Check if the geometry is a LineString
                      if (
                        geometry.type === "LineString" &&
                        geometry.coordinates &&
                        geometry.coordinates.length > 0
                      ) {
                        // Reverse the coordinates for LineString
                        return {
                          ...geometry,
                          coordinates: geometry.coordinates.slice().reverse(),
                        };
                      }
                      return geometry;
                    }
                  );

                  // Update the gData with the new geometries
                  gData = {
                    ...gData,
                    geometry: {
                      ...gData.geometry,
                      geometries: revisedGeometries,
                    },
                  };
                }
              }
            }
            let arrowPositions;
            if (gData?.geometry?.coordinates) {
              arrowPositions = calculateArrowPositions(
                gData?.geometry,
                polygonId
              );
            } else if (gData?.geometry?.geometries[0].type === "LineString") {
              arrowPositions = calculateArrowPositions(
                gData?.geometry?.geometries[0],
                polygonId
              );
            }
            return (
              <>
                <Marker
                  key={`crew-marker-${polygonId}-${i}`}
                  latitude={entryPoint.lat}
                  longitude={entryPoint.lon}
                  // offset={[0, -15]}
                >
                  <Circle color={color} text={i + 1} opacity={0.65}></Circle>
                </Marker>
                {arrowPositions &&
                  arrowPositions.map(({ position, angle }, idx) => (
                    <Marker
                      key={`arrow-marker-${polygonId}-${idx}`}
                      latitude={position[1]}
                      longitude={position[0]}
                      offset={[0, -1]}
                    >
                      <ArrowHead
                        key={`arrow-head-${polygonId}-${idx}`}
                        color={color}
                        opacity={0.65}
                        angle={angle.toString()}
                      />
                    </Marker>
                  ))}
              </>
            );
          });
        });
      }),
    [crewMemberDayWise]
  );

  useEffect(() => {
    if (!mapData || !mapData.features || mapData.features.length === 0) {
      return;
    }
    const bounds = calculateBounds(mapData.features);

    setViewport((prevViewport) => ({
      ...prevViewport,
      latitude: (bounds[1] + bounds[3]) / 2,
      longitude: (bounds[0] + bounds[2]) / 2,
      zoom: calculateZoom(bounds, prevViewport.width, prevViewport.height),
    }));
  }, [mapData]);

  useEffect(() => {
    if (crewMemberDayWise) {
      const transformedArray = [];

      Object.entries(crewMemberDayWise).forEach(([crewMemberId, crewData]) => {
        crewData.day.forEach((dayData) => {
          const routePlan = routeGeneration(dayData.polygon,dayData?.parkingId,lookUpData);

          routePlan.forEach((route) => {
            const entryPoint = route.entryPoint;
            const exitPoint = route.exitPoint;

            const transformedObject = {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [
                  [entryPoint.lon, entryPoint.lat],
                  [exitPoint.lon, exitPoint.lat],
                ],
              },
              equipmentName: null,
              properties: {
                crewMemberId: parseInt(crewMemberId),
                lineColor: crewData.crewMemberColor,
              },
            };

            transformedArray.push(transformedObject);
          });
        });
      });

      const final = {
        type: "FeatureCollection",
        features: transformedArray,
      };

      setLineData(final);
    }
  }, [crewMemberDayWise]); // Dependency array to re-run useEffect when crewMemberDayWise changes

  const crewPathHighlightForLines = useMemo(() => {
    if (filteredGeoJson) {
      const trueKeys = Object.keys(filteredGeoJson);
      if (trueKeys.length > 0) {
        return trueKeys.map((key) => {
          const geojsonData = filteredGeoJson[key];

          return (
            <React.Fragment key={`fragment-${key}`}>
              <Source
                id={`crew-path-layer-source-${key}`}
                type="geojson"
                data={geojsonData}
              >
                <Layer
                  key={`layer-path-${key}`}
                  type="line"
                  source={`crew-path-layer-source-${key}`}
                  id={`layer-path-${key}`}
                  paint={{
                    "line-width": 2,
                    "line-opacity": 1,
                    "line-color": [
                      "match",
                      ["get", "serviceType"],
                      "Hard Edge",
                      "#915c30",
                      "Soft Edge",
                      "#d69253",
                      "transparent",
                    ],
                  }}
                />
              </Source>
            </React.Fragment>
          );
        });
      }
    }

    return null;
  }, [filteredGeoJson]);

  const crewFillHighlight = useMemo(() => {
    if (filteredGeoJson) {
      const trueKeys = Object.keys(filteredGeoJson);

      if (trueKeys.length > 0) {
        return trueKeys.map((key) => {
          const geojsonData = filteredGeoJson[key];

          return (
            <React.Fragment key={`fragment-${key}`}>
              <Source
                id={`crew-layers-${key}`}
                type="geojson"
                data={geojsonData}
              >
                <Layer
                  key={`${key}`}
                  type="fill"
                  source={`crew-layers-${key}`}
                  filter={["==", ["geometry-type"], "Polygon"]}
                  id={`${key}`}
                  paint={{
                    "fill-color": [
                      "match",
                      ["get", "serviceType"],
                      "Rock Beds",
                      "#575c63",
                      "Mulch Beds",
                      "#e8d966",
                      "Turf",
                      "#c7e9c0",
                      "Hedge",
                      "#197901",
                      "transparent",
                    ],
                    "fill-opacity": 1,
                  }}
                />
              </Source>
            </React.Fragment>
          );
        });
      }
    }

    return null;
  }, [filteredGeoJson]);

  return (
    <>
      <Map
        {...viewport}
        onMove={(evt) => setViewport(evt.viewState)}
        style={{ height: "60vh" }}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
        mapStyle={mapStyle}
        ref={mapRef}
        onLoad={(event) => handleMapLoad(event)}
      >
        {/* fill for polygons bottom most layer */}
        <Source id="base-layers" type="geojson" data={mapData}>
          <Layer
            type="fill"
            id="fill-layer"
            filter={["==", ["geometry-type"], "Polygon"]}
            paint={{
              "fill-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                "red",
                [
                  "match",
                  ["get", "serviceType"],
                  "Rock Beds",
                  "#575c63",
                  "Mulch Beds",
                  "#e8d966",
                  "Turf",
                  "#c7e9c0",
                  "Hedge",
                  "#197901",
                  "transparent",
                ],
              ],
              "fill-opacity": 0.2,
            }}
          />
          {/* outlines for polygons for better visibility on maps */}
          <Layer
            type="line"
            id="outline-layer"
            paint={{
              "line-color": [
                "match",
                ["get", "serviceType"],
                "Mulch Beds",
                "#beac2d",
                "Turf",
                "#a1d99b",
                "Hedge",
                "#0b7000",
                "Rock Beds",
                "#5c5c5c",
                "transparent",
              ],
              "line-width": 2,
              "line-opacity": 0.3,
            }}
          />
          {/* Line layers that neeed to standout like hard and soft edges*/}
          <Layer
            type="line"
            id="line-layer"
            paint={{
              "line-color": [
                "match",
                ["get", "serviceType"],
                "Hard Edge",
                "#915c30",
                "Soft Edge",
                "#d69253",
                "obstacle",
                "#141414",
                "transparent",
              ],
              "line-width": 2,
              "line-opacity": 0.3,
            }}
          />
        </Source>

        {filteredGeoJson && crewPathHighlightForLines}
        {filteredGeoJson && crewFillHighlight}

        <Source id="plan-data" type="geojson" data={lineData}>
          <Layer
            type="line"
            id="crew-members-layer"
            paint={{
              "line-color": ["get", "lineColor"],
              "line-width": [
                "case",
                ["==", ["get", "pathId"], hoveredPolygon?.id || null],
                2,
                hoveredPolygon ? 0.7 : 2,
              ],
              "line-dasharray": [2, 2],
              "line-opacity": [
                "case",
                ["==", ["get", "pathId"], hoveredPolygon?.id || null],
                1,
                hoveredPolygon ? 0.7 : 2,
              ],
            }}
          />
        </Source>

        {crewMemberDayWise && crewWiseMarkers}

        {lookUpData &&
          lookUpData?.parkings &&
          Object.keys(lookUpData.parkings).map((parkingId) => {
            const parking = lookUpData.parkings[parkingId];
            return (
              <div style={{ zIndex: 2 }} key={parking.parkingId}>
                <Marker
                  latitude={parking.point.lat}
                  longitude={parking.point.lon}
                  offset={[0, -15]}
                  zIndex={2}
                  onClick={(e) => {
                    e.originalEvent.stopPropagation();
                    setSelectedParking(parking);
                  }}
                >
                  <Pin text="P" color="#ffa600"></Pin>
                </Marker>
              </div>
            );
          })}
        {selectedParking && (
          <Popup
            latitude={selectedParking?.point?.lat}
            longitude={selectedParking?.point?.lon}
            onClose={() => setSelectedParking(null)}
            offsetTop={-30}
          >
            <div>
              <h3>{selectedParking?.parkingType}</h3>
              <p>{selectedParking?.address}</p>
              <p>Parking Id : {selectedParking?.parkingId}</p>
              <p>Parking Type : {selectedParking?.parkingType}</p>
              {/* Add more details as needed */}
            </div>
          </Popup>
        )}
      </Map>
    </>
  );
};

export default CrewMemberMap;
