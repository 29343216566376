import {
  Checkbox,
  Drawer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import "./PlannerDetailsDrawer.css";
import { getLengthUnit } from "../../components/PropertyWidget/PropertyWidgets";
import {
  convertTime,
  formatDistance,
  truncateString,
} from "../../utils/common";
import {
  setCrewMemberDayWiseFilteredData,
  setGeoJsonFilteredData,
  setSelectedCrewMembersId,
  setSelectedServices,
} from "../../slices/globalSlice";
import { IoClose } from "react-icons/io5";
import SelectPlanModal from "../../Modals/SelectPlanModal/SelectPlanModal";
import useViewportWidth from "../../Hooks/useViewportWidth";
import PlannerInputData from "./PlannerInputData";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    fontWeight: 700,
  },
  whiteSpace: "nowrap",
}));

const PlannerDetailsDrawer = ({
  togglePlannerDrawer,
  isPlannerDrawerOpen,
  isSolver = false,
}) => {
  const width = useViewportWidth();
  const initialCrewData = useSelector(
    (state) => state.global.initialCrewMembers
  );
  const [isSelectPlanOpen, setIsSelectPlanOpen] = useState(false);
  const isPremium = useSelector((state) => state.global.isPremium);
  const mapData = useSelector((state) => state.global.mapData);
  const [selectedCrewMembers, setSelectedCrewMembers] = useState(
    Object.keys(initialCrewData).reduce((acc, crewMemberId) => {
      acc[crewMemberId] = false;
      return acc;
    }, {})
  );

  useEffect(() => {
    if (mapData) {
      const uniqueServices = {};
      mapData.features.forEach((feature) => {
        const serviceType = feature.properties.serviceType;
        if (!uniqueServices[serviceType]) {
          uniqueServices[serviceType] = true;
        }
      });
      dispatch(setSelectedServices(uniqueServices));
    }
  }, [mapData]);
  const dispatch = useDispatch();
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const lookUp = useSelector((state) => state.global.lookUpData);
  const selectedServices = useSelector(
    (state) => state.global.selectedServices
  );

  useEffect(() => {
    if (initialCrewData) {
      setSelectedCrewMembers(
        Object.keys(initialCrewData).reduce((acc, crewMemberId) => {
          acc[crewMemberId] = false;
          return acc;
        }, {})
      );
    }
  }, [initialCrewData, selectedDay]);

  useEffect(() => {
    if (mapData) {
      const filteredFeatures = mapData.features.filter(
        (feature) => selectedServices[feature.properties.serviceType]
      );

      // Construct a new GeoJSON object containing only the filtered features
      const newFilteredGeoJSON = {
        ...mapData,
        features: filteredFeatures,
      };
      dispatch(setGeoJsonFilteredData(newFilteredGeoJSON));
    }
  }, [selectedServices, mapData]);

  const getFilteredData = () => {
    // isSolver is used to render map on create plan screen, this is because we are rendering all of the crewmembers data on create plan screen
    if (isSolver) {
      return Object.keys(initialCrewData).reduce((acc, id) => {
        acc[id] = { ...initialCrewData[id] };
        // Filter day data based on the selected day
        if (selectedDay == "All") acc[id].day = acc[id].day;
        else {
          acc[id].day = acc[id].day.filter((day) => day.day === selectedDay);
        }
        return acc;
      }, {});
    } else {
      return Object.keys(initialCrewData)
        .filter((crewMemberId) => selectedCrewMembers[crewMemberId])
        .reduce((acc, id) => {
          acc[id] = { ...initialCrewData[id] };
          // Filter day data based on the selected day
          if (selectedDay == "All") acc[id].day = acc[id].day;
          else {
            acc[id].day = acc[id].day.filter((day) => day.day === selectedDay);
          }
          return acc;
        }, {});
    }
  };

  useEffect(() => {
    const filteredData = getFilteredData();
    dispatch(setCrewMemberDayWiseFilteredData(filteredData));
  }, [selectedCrewMembers, dispatch]);

  const handleCrewMemberCheckboxChange = (id, isChecked) => {
    setSelectedCrewMembers((prev) => ({ ...prev, [id]: isChecked }));
  };
  useEffect(() => {
    dispatch(setSelectedCrewMembersId(selectedCrewMembers));
  }, [selectedCrewMembers, dispatch]);

  const getCrewMemberColor = (crewMemberId) => {
    const crewData = lookUp?.crewMemberDayWise;
    if (crewData[crewMemberId]) {
      return crewData[crewMemberId].crewMemberColor;
    } else {
      return "Crew member not found";
    }
  };

  const handleServicesCheckboxChange = (serviceType) => {
    dispatch(setSelectedServices(serviceType));
  };

  const servicesArray = lookUp?.services ? Object.values(lookUp?.services) : [];
  const crewMembersArray = lookUp?.crewMembers
    ? Object.entries(lookUp?.crewMembers)
    : [];


  return (
    <Drawer
      anchor="left"
      open={isPlannerDrawerOpen}
      onClose={isPlannerDrawerOpen}
      hideBackdrop
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          width: width < 768 ? "100vw" : "25vw",
          top: "8vh",
          zIndex: width < 768 ? 10000 : 1,
        },
      }}
    >
      <div className="sidebarDrawer">
        <div className="plannerDetailsContianer">
          {lookUp && (
            <>
              <div className="propertyDetailsHeader">
                <div className="plannerPropertyDetails">
                  <div className="propertyBox">
                    <div className="propertyTitleValue">
                      {lookUp?.summary?.clientName} - {lookUp?.summary?.site}
                    </div>
                  </div>
                  {lookUp?.summary?.address && (
                    <div className="propertyBox">
                      <div className="propertyTitleContainer">
                        <MdLocationPin fontSize={18} />
                        <div className="propertyTitle">
                          {lookUp?.summary?.address}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="sidebarCloseButton">
                  <IoClose size={24} onClick={togglePlannerDrawer} />
                </div>
              </div>
              <div className="servicesContainer">
                <h3 className="subTitlePlanner">Service Details</h3>
                <div>
                  <TableContainer component={Paper}>
                    <Table
                      // sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Service</StyledTableCell>
                          <StyledTableCell align="center">Measurments</StyledTableCell>
                          <StyledTableCell align="center">Time</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {servicesArray.map((service, index) => {
                          const { serviceName, totalTime, totalArea } = service;
                          const formattedServiceName = serviceName.replace(
                            "_",
                            " "
                          );
                          return (
                            <TableRow
                              key={totalTime * index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell component="th" scope="row">
                                <div className="plannerNameContainer">
                                  <div className="checkboxContainer">
                                    <Checkbox
                                      size="small"
                                      checked={
                                        selectedServices[formattedServiceName]
                                          ? selectedServices[
                                              formattedServiceName
                                            ]
                                          : false
                                      }
                                      onChange={() =>
                                        handleServicesCheckboxChange(
                                          formattedServiceName
                                        )
                                      }
                                    />
                                  </div>

                                  <div className="serviceNameContainer">
                                    <Tooltip title={formattedServiceName}>
                                      <div>
                                        {truncateString(
                                          formattedServiceName,
                                          10
                                        )}
                                      </div>
                                    </Tooltip>
                                    <div
                                      className={`Plannercolor ${serviceName.replace(
                                        " ",
                                        "_"
                                      )}`}
                                    />
                                  </div>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {totalArea?.toFixed(0)}{" "}
                                {getLengthUnit(formattedServiceName)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {convertTime(totalTime?.toFixed(2))}
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <div className="plannerCrewMembers">
                <h3 className="subTitlePlanner">Crew Member Details</h3>
                <div className="tableContainer">
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell align="center">Work</StyledTableCell>
                          <StyledTableCell align="center">
                            Travel
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Distance
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {crewMembersArray
                          .reverse()
                          .map(([crewMemberId, crewMember],index) => {
                            const {
                              name,
                              title,
                              totalAssignTimeOfWork,
                              totalAssignTravelDistance,
                              totalAssignTravelTime,
                            } = crewMember;
                            const crewMemberColor =
                              getCrewMemberColor(crewMemberId);
                            return (
                              <TableRow
                                key={name*index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell component="th" scope="row">
                                  <div className="plannerNameContainer">
                                    <div className="checkboxContainer">
                                      <Checkbox
                                        checked={
                                          selectedCrewMembers[crewMemberId]
                                        }
                                        onChange={(e) =>
                                          handleCrewMemberCheckboxChange(
                                            crewMemberId,
                                            e.target.checked
                                          )
                                        }
                                        size="small"
                                      />
                                    </div>
                                    <div className="serviceNameContainer">
                                      <Tooltip title={name}>
                                        <div>{truncateString(name, 8)}</div>
                                      </Tooltip>
                                      <div
                                        className={`Plannercolor`}
                                        style={{
                                          backgroundColor: crewMemberColor,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {convertTime(totalAssignTimeOfWork)}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {convertTime(totalAssignTravelTime)}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {formatDistance(totalAssignTravelDistance)}
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <div className="plannerDetails">
                <PlannerInputData />
              </div>
            </>
          )}
        </div>
      </div>
      <SelectPlanModal
        isOpen={isSelectPlanOpen}
        setIsOpen={setIsSelectPlanOpen}
      />
    </Drawer>
  );
};

export default PlannerDetailsDrawer;
