import React from "react";
import { DeckGlOverlay } from "./deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";
import { getServiceColorRgb, hexToRgb } from "../../utils/common";
import { useSelector } from "react-redux";

function getCrewMemberWiseServiceLayers(
  data,
  serviceWise,
  handleHoveredPolygon
) {
  if (!data) return [];
  return [
    new GeoJsonLayer({
      id: "geojsonCrewWiseServiceWise",
      data: data,
      filled: true,
      opacity: 1,
      getLineWidth: 0.2,
      visible: serviceWise ? true : false,
      getFillColor: (f) => getServiceColorRgb(f?.properties?.serviceType),
      pickable: true,
      onHover: (info) => handleHoveredPolygon(info),
    }),
    new GeoJsonLayer({
      id: "geojsonCrewWiseServiceWiseLines",
      data: data,
      filled: false,
      getLineWidth: 0.2,
      opacity: 0.4,
      visible: serviceWise ? true : false,
      getLineColor: (f) => getServiceColorRgb(f?.properties?.serviceType),
      pickable: true,
      onHover: (info) => handleHoveredPolygon(info),
    }),
    new GeoJsonLayer({
      id: "geojsonCrewWise",
      data: data,
      stroked: true,
      filled: true,
      getLineWidth: 0.2,
      opacity: !serviceWise ? 0.4 : 1,
      visible: !serviceWise ? true : false,
      lineWidthMinPixels: 2,
      getFillColor: (f) => hexToRgb(f?.properties?.color),
      getLineColor: (f) => hexToRgb(f?.properties?.color),
      pickable: true,
      onHover: (info) => handleHoveredPolygon(info),
    }),
  ];
}
const CrewMemberServiceLayers = ({ filteredGeoJson, handleHoveredPolygon }) => {
  const serviceWise = useSelector((state) => state.global.serviceWise);
  return (
    <DeckGlOverlay
      layers={getCrewMemberWiseServiceLayers(
        filteredGeoJson,
        serviceWise,
        handleHoveredPolygon
      )}
    />
  );
};

export default CrewMemberServiceLayers;
