import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeStep: 0,
    stepsLabels: [
      {
        label: "Select Location",
        description: "",
        id:1
      },
      {
        label: "Create Parcel",
        description: "",
        id:2
      },
      {
        label: "Add parking Spot",
        description: "",
        id:3
      },
      {
        label: "Select Services",
        description: "",
        id:4
      },
      {
        label: "Crew",
        description: "",
        id:5
      },
    ],
    locationPinCoordinate: null,
    locationName: "",
    parkings:[],
    services: [
      {
        serviceSkill: {
          skillName: "Mowing",
          skillId: 1,
          description: "Mowing"
        },
        name: "Turf",
        serviceEquipment: {
          productionRate: 90000,
          transitionPace: 79200,
          description: "72 Inch Riding Mower",
          equipmentName: "72 Inch Riding Mower",
          equipmentId: 1
        },
        description: "Turf",
        serviceId: 1
      },
      {
        serviceSkill: {
          skillName: "Hard Edging",
          skillId: 2,
          description: "Hard Edging"
        },
        name: "Hard Edge",
      serviceEquipment: {
          productionRate: 8000,
          transitionPace: 10000,
          description: "Hard Edger",
          equipmentName: "Hard Edger",
          equipmentId: 5
        },
        description: "Hard Edge",
        serviceId: 4
      },
      {
        serviceSkill: {
          skillName: "General Skills",
          skillId: 3,
          description: "General Skills"
        },
        name: "String Trimmer",
        serviceEquipment: {
          productionRate: 4000,
          transitionPace: 10000,
          description: "String Trimmer",
          equipmentName: "String Trimmer",
          equipmentId: 6
        },
        description: "String Trimmer",
        serviceId: 5
      },
      {
        serviceSkill: {
          skillName: "General Skills",
          skillId: 3,
          description: "General Skills"
        },
        name: "Cleanup Blowing",
        serviceEquipment: {
          productionRate: 9000,
          transitionPace: 10000,
          description: "Backpack Blower",
          equipmentName: "Backpack Blower",
          equipmentId: 7
        },
        description: "Cleanup Blowing",
        serviceId: 6
      }
    ],
    parcelGeoJSON: null,
};

const stepperFormSlice = createSlice({
  name: "stepperForm",
  initialState,
  reducers: {
    reset: () => initialState,
    setActiveStep: (state, action) => {
      return {
        ...state,
        activeStep: action.payload,
      };
    },
    setStepsLabels: (state, action) => {
      const {id,description} = action.payload
      const updatedLabelData = state.stepsLabels.map((step) => {
        if(step.id===id){
          return {
            ...step,
            description: description
          }
        }
        return step
      })
      state.stepsLabels = updatedLabelData
    },
    handlePrevStep: (state, action) => {
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    },
    handleNextStep: (state, action) => {
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    },
    setLocationPinCoordinate: (state, action) => {
      return {
        ...state,
        locationPinCoordinate: action.payload,
      };
    },
    setLocationName: (state, action) => {
      return {
        ...state,
        locationName: action.payload,
      };
    },
    setParkings: (state, action) => {
      return {
        ...state,
        parkings: action.payload,
      };
    },
    setParcelGeoJSON: (state, action) => {
      return {
        ...state,
        parcelGeoJSON: action.payload,
      };
    },
  },
});

export const {
  reset,
  setActiveStep,
  setStepsLabels,
  handlePrevStep,
  handleNextStep,
  setLocationPinCoordinate,
  setLocationName,
  setParkings,
  setParcelGeoJSON
} = stepperFormSlice.actions;

export default stepperFormSlice.reducer;
