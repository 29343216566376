import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import "./PropertyWidget.css";
import { convertTime } from "../../utils/common";

export function getImagePath(serviceName) {
  switch (serviceName) {
    case "Lawn Mowing":
      return "/images/turf.avif";
    case "Turf":
      return "/images/turf.avif";
    case "Soft Edge":
      return "/images/softEdges.jpg";
    case "Soft Edging":
      return "/images/softEdges.jpg";
    case "String Trimmer":
      return "/images/softEdges.jpg";
    case "Hard Edge":
      return "/images/hardEdges.jpg";
    case "Hard Edging":
      return "/images/hardEdges.jpg";
    case "Mulching":
      return "/images/mulching.avif";
    case "Mulch Beds":
      return "/images/mulching.avif";
    case "Hedging":
      return "/images/hedging.jpg";
    case "Rock Beds":
      return "/images/rockBeds.jpg";
    case "Cleanup Blowing":
      return "/images/blower.jpeg";
    default:
      return "/Images/turf.jpg";
  }
}
export function getName(serviceName) {
  switch (serviceName) {
    case "Lawn Mowing":
      return "Turf Count :"; // Replace with your image paths
    case "Turf":
      return "Turf Count :"; // Replace with your image paths
    case "Soft Edge":
      return "Soft Edges :";
    case "Soft Edging":
      return "Soft Edges :";
    case "String Trimmer":
      return "String Trimmer :";
    case "Hard Edge":
      return "Hard Edges :";
    case "Hard Edging":
      return "Hard Edges :";
    case "Mulch Beds":
      return "Mulch Beds :";
    case "Mulching":
      return "Mulch Beds :";
    case "Rock Beds":
      return "Rock Beds :";
    default:
      return "Polygon Count :"; // A default image if no match is found
  }
}
export function getLengthOrArea(serviceName) {
  switch (serviceName) {
    case "Lawn Mowing":
      return "Area";
    case "Turf":
      return "Area";
    case "Soft Edge":
      return "Length";
    case "Soft Edging":
      return "Length";
    case "String Trimmer":
      return "Length";
    case "Hard Edge":
      return "Length";
    case "Hard Edging":
      return "Length";
    case "Mulching":
      return "Area";
    case "Mulch Beds":
      return "Area";
    case "Rock Beds":
      return "Area";
    default:
      return "Area"; // A default image if no match is found
  }
}
export function getLengthUnit(serviceName) {
  switch (serviceName) {
    case "Lawn Mowing":
      return "ft²";
    case "Turf":
      return "ft²"; 
    case "Soft Edge":
      return "ft";
    case "Soft Edging":
      return "ft";
    case "String Trimmer":
      return "ft";
    case "Hard Edge":
      return "ft";
    case "Hard Edging":
      return "ft";
    case "Mulching":
      return "ft²";
    case "Mulch Beds":
      return "ft²";
    case "Rock Beds":
      return "ft²";
    default:
      return "ft²"; // A default image if no match is found
  }
}
export function getAcres(serviceName, value) {
  switch (serviceName) {
    case "Lawn Mowing":
      return ` ${(value / 43560).toFixed(1)} acres `;
    case "Turf":
      return ` ${(value / 43560).toFixed(1)} acres `;
    case "Soft Edge":
      return ` ${(value / 5280).toFixed(1)} miles `;
    case "Soft Edging":
      return ` ${(value / 5280).toFixed(1)} miles `;
    case "String Trimmer":
      return ` ${(value / 5280).toFixed(1)} miles `;
    case "Hard Edge":
      return ` ${(value / 5280).toFixed(1)} miles `;
    case "Hard Edging":
      return ` ${(value / 5280).toFixed(1)} miles `;
    case "Mulching":
      return ` ${(value / 43560).toFixed(1)} acres `;
    case "Mulch Beds":
      return ` ${(value / 43560).toFixed(1)} acres `;
    case "Rock Beds":
      return ` ${(value / 43560).toFixed(1)} acres `;
    default:
      return ""; // A default image if no match is found
  }
}
const PropertyWidgets = ({ services }) => {

  // Convert the services object into an array of its values to map over it
  const servicesArray = services ? Object.values(services) : [];

  return (
    <div className="propertyDetailsWrapper">
      <h2>Service Details</h2>
      <div>
        <Grid container justifyContent={"center"} spacing={5}>
          {servicesArray.length > 0 &&
            servicesArray.map((service, index) => {
              const { serviceName, totalTime, totalArea } =
                service;

              const formattedServiceName = serviceName.replace("_", " "); // Adjust for display
              const imagePath = getImagePath(formattedServiceName);

              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={service.serviceId}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="180"
                      image={imagePath}
                      alt={formattedServiceName}
                    />
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {formattedServiceName}
                      </Typography>
                      <div className="detailsContainer">
                        <div className="detailsTitle">
                          {getLengthOrArea(formattedServiceName)}
                        </div>
                        <div className="detailsServiceValue">
                          {getAcres(formattedServiceName, totalArea?.toFixed(0))}
                          {" ( "}
                          {totalArea?.toFixed(0)}{" "}
                          {getLengthUnit(formattedServiceName)}
                          {" )"}
                        </div>
                      </div>
                      <div className="detailsContainer">
                        <div className="detailsTitle">Estimated Time</div>
                        <div className="detailsServiceValue">
                          {convertTime(totalTime?.toFixed(2))}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </div>
  );
};

export default PropertyWidgets;
