import { Checkbox, Drawer, Slider, Tooltip } from "@mui/material";
import React from "react";
import { FaPlay } from "react-icons/fa";
import { FaCirclePause } from "react-icons/fa6";
import { IoIosRemoveCircle } from "react-icons/io";
import { VscDebugRestart } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  setAnimationData,
  setInitialCrewMembers,
  setLookUpData,
  setMapData,
  setServiceWise,
  setShowArrowMarkers,
  setShowConnectingLines,
  setShowNumberCircles,
  setShowObstacles,
} from "../../slices/globalSlice";
import MapStyle from "../../components/Mapbox/MapStyle";
import { IoClose } from "react-icons/io5";
import {
  ShuffleJson,
  assignCrewMemberColors,
  assignCrewMemberColorsToAnimation,
  calculateServicesTotal,
  calculateTotalAssignmentsForCrewMember,
  getServiceName,
} from "../../utils/common";
import { generateRoutePlanForAnimation } from "../../utils/routePlanforAnimation";
import AnimationUploadButton from "./AnimationUploadButton";
import LookUpUploadButton from "./LookUpUploadButton";
import GeoJSONUploadButton from "./GeoJSONUploadButton";

const MapControlsDrawer = ({
  isAnimationDrawerOpen,
  startAnimation,
  stopAnimation,
  restartAnimation,
  completedLines,
  handleClearAnimation,
  animationSpeed,
  handleAnimationSpeed,
  animationState,
  toggleMapControlsDrawer,
  isSolver = false,
}) => {
  const {
    global: {
      serviceWise,
      mapData,
      showNumberCircles,
      showArrowMarkers,
      showConnectingLines,
      showObstacles,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleGeoFileUpload = (file) => {
    ShuffleJson(file);
    dispatch(setMapData(file));
  };

  const handleLookupFileUpload = (file) => {
    if (file) {
      const requiredData = file.lookUpOutput ? file.lookUpOutput : file;
      const final = calculateTotalAssignmentsForCrewMember(requiredData);
      const withServices = calculateServicesTotal(final);
      const routePlan = generateRoutePlanForAnimation(withServices, mapData);
      const crewColors = assignCrewMemberColors(routePlan);
      dispatch(setLookUpData(crewColors));
      dispatch(setInitialCrewMembers(withServices?.crewMemberDayWise));
    }
  };
  const handleAnimationFileUpload = (file) => {
    const animatedData = assignCrewMemberColorsToAnimation(file);
    dispatch(setAnimationData(animatedData));
  };

  return (
    <Drawer
      anchor="top"
      open={isAnimationDrawerOpen}
      hideBackdrop
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          borderRadius: "0 0 10px 10px",
          width: 220,
          position: "fixed",
          bottom: 0,
          top: "8vh",
          right: 10,
          left: "auto",
          zIndex: "1",
          maxHeight: "35vh",
        },
      }}
    >
      <div className="drawerWrapper">
        <div className="mainContainer">
          <div className="RightControls">
            <div className="mapControlsTitleContainer">
              <div className="controlsTitle">Map Controls</div>
              <div className="closeButton">
                <IoClose size={24} onClick={toggleMapControlsDrawer} />
              </div>
            </div>
            <div className="mapFiltersContainer">
              <div className="mapFilters">
                <label>Service Wise</label>
                <div>
                  {!isSolver && (
                    <Checkbox
                      size="small"
                      checked={serviceWise}
                      onChange={(e) =>
                        dispatch(setServiceWise(e.target.checked))
                      }
                    />
                  )}
                </div>
              </div>
              <div className="mapFilters">
                <label>Show Number Circles</label>
                <div>
                  {!isSolver && (
                    <Checkbox
                      size="small"
                      checked={showNumberCircles}
                      onChange={(e) =>
                        dispatch(setShowNumberCircles(e.target.checked))
                      }
                    />
                  )}
                </div>
              </div>
              <div className="mapFilters">
                <label>Direction Arrows</label>
                <div>
                  {!isSolver && (
                    <Checkbox
                      size="small"
                      checked={showArrowMarkers}
                      onChange={(e) =>
                        dispatch(setShowArrowMarkers(e.target.checked))
                      }
                    />
                  )}
                </div>
              </div>
              <div className="mapFilters">
                <label>Connecting Lines</label>
                <div>
                  {!isSolver && (
                    <Checkbox
                      size="small"
                      checked={showConnectingLines}
                      onChange={(e) =>
                        dispatch(setShowConnectingLines(e.target.checked))
                      }
                    />
                  )}
                </div>
              </div>
              <div className="mapFilters">
                <label>Obstacles</label>
                <div>
                  {!isSolver && (
                    <Checkbox
                      size="small"
                      checked={showObstacles}
                      onChange={(e) =>
                        dispatch(setShowObstacles(e.target.checked))
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="controlsTitle">Map Styles</div>
            {/* <MapStyle />
          <div className="controlsTitle">Animation Controls</div>
          <div className="animationControlsWrapper">
            <div className="animationControls">
              {animationState === "stopped" ? (
                <Tooltip title="Play" arrow>
                  <span>
                    <FaPlay
                      fontSize={23}
                      cursor="pointer"
                      color="#039a03"
                      onClick={startAnimation}
                    />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title="Pause" arrow>
                  <span>
                    <FaCirclePause
                      fontSize={23}
                      color="red"
                      cursor="pointer"
                      onClick={stopAnimation}
                    />
                  </span>
                </Tooltip>
              )}
              <Tooltip title="Restart" arrow>
                <span>
                  <VscDebugRestart
                    fontSize={26}
                    cursor="pointer"
                    color="#0acbdd"
                    onClick={restartAnimation}
                  />
                </span>
              </Tooltip>
              {completedLines.length > 0 && (
                <Tooltip title="Clear Animation" arrow>
                  <span>
                    <IoIosRemoveCircle
                      fontSize={26}
                      cursor="pointer"
                      color="#ff0000"
                      onClick={handleClearAnimation}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
            <div>Speed Control</div>
            <Slider
              value={animationSpeed}
              onChange={handleAnimationSpeed}
              step={1}
              disabled={animationState === "playing"}
              min={1}
              max={10}
              aria-label="Small"
              valueLabelDisplay="auto"
            />
          </div> */}
          </div>
        </div>
        <div className="buttonContainer">
          <AnimationUploadButton onFileUpload={handleAnimationFileUpload} />
          <LookUpUploadButton onFileUpload={handleLookupFileUpload} />
          <GeoJSONUploadButton onFileUpload={handleGeoFileUpload} />
        </div>
      </div>
    </Drawer>
  );
};

export default MapControlsDrawer;
