import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import MainScreen from '../screens/MainScreen';
import Header from '../components/Header/Header';
import Login from '../screens/Login/Login';
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from '../utils/ProtectedRoute';
import CrewMemberDetails from '../screens/CrewMemberDetails/CrewMemberDetails';
import { ToastContainer } from 'react-toastify';
import { ROUTES } from '../utils/constants';
import Survey from '../screens/Survey/Survey';
import Properties from '../screens/Properties/Properties';
import NotFound from '../screens/NotFound/NotFound';
import Register from '../screens/Register/Register';
import VerifyEmail from '../screens/VerifyEmail/VerifyEmail';
import NewUser from '../screens/NewUser/NewUser';

const AppRoutes = () => {
  
  return (
    <>
    <ToastContainer /> 
      <Header />
      <Routes>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.REGISTER} element={<Register />} />
        <Route path={ROUTES.VERIFYEMAIL} element={<VerifyEmail />} />
        <Route path={ROUTES.NEWUSER} element={<NewUser />} />
        <Route path="*" element={<NotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route path={ROUTES.SURVEY} element={<Survey />} />
          <Route path={ROUTES.PARCEL} element={<Survey />} />
          <Route path={ROUTES.MAP} element={<MainScreen />} />
          <Route path={ROUTES.PROPERTIES} element={<Properties />} />
          {/* <Route path={ROUTES.LANDINGPAGE} element={<LandingPage />} /> */}
          <Route path={ROUTES.CREWMEMBERDETAILS} element={<CrewMemberDetails />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
