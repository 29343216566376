import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { setIsLogin, setToken, setUser } from "../../slices/globalSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../services/Auth";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { useDispatch } from "react-redux";
import { useGetLocationGroupsMutation } from "../../services/Properties";
import {
  setIsLocationSet,
  setLocationGroupData,
} from "../../slices/workPlanSlice";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import "./Login.css";
import { getCustomColor, theme } from "../../utils/theme";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
export default function Login() {
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm();
  const blackShade1 = getCustomColor("blackShade1");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const [getLocationGroups] = useGetLocationGroupsMutation();
  const onSubmit = async (data) => {
    const values = {
      username: data.email,
      password: data.password,
    };
    try {
      const response = await login(values).unwrap();
      dispatch(setToken(response?.token));
      dispatch(setIsLogin(true));
      dispatch(setUser(response?.user));
      localStorage.setItem("accessToken", response?.token);
      localStorage.setItem("user", JSON.stringify(response?.user));

      if (response) {
        const locationData = await getLocationGroups(
          response?.user?.organization
        ).unwrap();
        dispatch(setLocationGroupData(locationData?.results));
        localStorage.setItem(
          "locationGroups",
          JSON.stringify(locationData?.results)
        );
        if (response?.user?.organization?.length === 0) {
          navigate("/newuser");
        } else {
          navigate(`/properties`);
        }
      }
      toast.success("logged in successfully", {
        autoClose: 1000,
        hideProgressBar: true,
      });
      dispatch(setIsLocationSet(false));
    } catch (error) {
      toast.error(error?.data?.detail, {
        autoClose: 1000,
        hideProgressBar: true,
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="authContainer"
      >
        <Typography
          component="h3"
          fontWeight="600"
          sx={{ color: blackShade1.main }}
          variant="h5"
        >
          Login
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleFormSubmit(onSubmit)}
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            fullWidth
            size="small"
            id="email"
            label="Email"
            name="email"
            inputProps={{
              form: {
                autocomplete: "off",
              },
            }}
            autoFocus
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Enter a valid email address",
              },
            })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            size="small"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id="password"
            autoComplete="password"
            {...register("password", {
              required: "Password is required",
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message:
                  "Password must be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a special character",
              },
            })}
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ""}
          />

          <SecondaryButton
            type="button"
            fullWidth
            marginTop="15px"
            marginBottom="10px"
            variant="contained"
            text="Forgot Password"
            background="#E0FFC4"
            textColor="#3F8203"
          />

          <Button
            type="submit"
            fullWidth
            disabled={isLoading}
            variant="contained"
            sx={{ mt: 1, mb: 2, boxShadow: "none" }}
          >
            {isLoading ? <CircularProgress size={24} /> : "Login"}
          </Button>
          <Typography
            component="div"
            fontWeight="500"
            sx={{ color: blackShade1.main }}
            variant="div"
            className="createOne"
          >
            Don't have an account?{" "}
            <Typography
              component="span"
              fontWeight="500"
              sx={{ color: "primary.dark" }}
              variant="span"
              onClick={() => navigate("/register")}
            >
              Create one
            </Typography>
          </Typography>
        </Box>
      </Box>
      <div className="patternLeft">
        <img src="/images/patternsLeft.png" />
      </div>
      <div className="patternRight">
        <img src="/images/patternsRight.png" />
      </div>
    </>
  );
}
