import React, { useEffect, useState } from "react";
import { convertTimestampToFormattedDate, getEquipmentTimeFormatted } from "../../utils/common";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const EquipmentNeeded = ({ currentDay, crewMember }) => {
  const params = useParams()
    const [serviceTimeData, setServiceTimeData] = useState(null);
  const lookup = useSelector((state) => state.global.lookUpData);

  const getCrewMemberName = (crewMemberId) => {
    const crewData = lookup?.crewMembers;
    if (crewData[crewMemberId]) {
      return crewData[crewMemberId].name;
    } else {
      return "Crew member not found";
    }
  };

  const getServiceName = (serviceId) => {
    const servicesData = lookup?.services;
    if (servicesData[serviceId]) {
      return servicesData[serviceId].serviceName;
    } else {
      return "Service not found";
    }
  };
  const getEquipmentName = (crewEquipmentId) => {
    const equipments = lookup?.equipments;
    if (equipments[crewEquipmentId]) {
      return equipments[crewEquipmentId].type;
    } else {
      return "Equipment not found";
    }
  };

  const getEquipmentNeeded = (lookup) => {
    const crewMemberDayWise = lookup.crewMemberDayWise;
    const timelineData = [];

    for (const crewId in crewMemberDayWise) {
      const crewData = crewMemberDayWise[crewId];
      const DayPolygons = crewData.day.filter(
        (dayData) => dayData.day == currentDay
      );
      DayPolygons.forEach((dayData) => {
        const polygons = dayData.polygon;
        let currentServiceName = null;
        let currentServiceId = null;
        let currentStartTime = null;
        let currentEndTime = null;
        let currentCrewMemberId = null;
        let currentEquipment = null;
        let currentPolygonId = null;
        polygons.forEach((polygonId, index) => {
          const polygon = lookup.polygons[polygonId];

          if (currentServiceName === null) {
            // This is the first polygon

            currentServiceName = getServiceName(polygon.serviceId);
            currentServiceId = polygon.serviceId;
            currentStartTime = polygon.arrivalTime;
            currentCrewMemberId = polygon.crewMemberId;
            currentEquipment = getEquipmentName(polygon.crewEquipmentId);
            currentPolygonId = polygonId;
          } else if (currentServiceName === getServiceName(polygon.serviceId)) {
            // Same service, update the end time
            currentEndTime = polygon.departureTime;
          } else {
            // Service name changed, push the previous task and start a new one
            pushTask(
              currentStartTime,
              currentEndTime,
              currentCrewMemberId,
              currentEquipment
            );

            currentServiceName = getServiceName(polygon.serviceId);
            currentStartTime = polygon.arrivalTime;
            currentServiceId = polygon.serviceId;
            currentCrewMemberId = polygon.crewMemberId;
            currentEquipment = getEquipmentName(polygon.crewEquipmentId);
            currentPolygonId = polygonId;
          }

          // Always update the end time to the current polygon's departure time
          currentEndTime = polygon.departureTime;

          // If it's the last polygon, push the task
          if (index === polygons.length - 1) {
            pushTask(
              currentStartTime,
              currentEndTime,
              currentCrewMemberId,
              currentEquipment
            );
          }
        });
      });

      function pushTask(startTime, endTime, crewMemberId, equipmentName) {
        const task = {
          id: `${crewMemberId}`,
          equipment: equipmentName,
          start: convertTimestampToFormattedDate(startTime),
          end: convertTimestampToFormattedDate(endTime),
        };

        if (crewMemberId == params.id) {
          timelineData.push(task);
        }
      }
    }
    return timelineData;
  };

  useEffect(() => {
    if (currentDay && lookup) {
      const serviceTimeData = getEquipmentNeeded(lookup);

      setServiceTimeData(serviceTimeData);
    }
  }, [currentDay, lookup]);
  return (
    <div>
      {serviceTimeData &&
        serviceTimeData.map((item) => {
          return (
            <div className="equipmentContainer">
              <div className="equipmentTitle">{item.equipment} - </div>
              <div className="equipmentContent">
                {getEquipmentTimeFormatted(item.start)} -{" "}
                {getEquipmentTimeFormatted(item.end)}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default EquipmentNeeded;
