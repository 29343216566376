import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleNextStep,
  setParcelGeoJSON,
} from "../../../slices/stepperFormSlice";
import { useCreateParcelMutation } from "../../../services/OpenApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const CreateParcelForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const {
    drawing: { now },
  } = useSelector((state) => state);
  const user = useSelector((state) => state.global.user);
  const [createParcel, { isLoading }] = useCreateParcelMutation();

  const onSubmit = async () => {
    let geojsonStructure = {
      type: "FeatureCollection",
      features: [],
    };
    now.map((shape) => {
      return geojsonStructure.features.push(shape.geojson);
    });
    const geoJson = geojsonStructure;
    dispatch(setParcelGeoJSON(geoJson));
    try {
      const response = await createParcel({
        orderId: params?.id,
        orgName: user?.organization,
        content: JSON.stringify(geoJson),
      });
      if (response) {
        toast.success("Parcel created successfully", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        dispatch(handleNextStep());
      }
    } catch (err) {
      console.log("error => ", err);
    }
  };
  return (
    <div className="StepsFormContainer">
      <h3 className="StepsFormContainerHeading">Create Parcel</h3>
      {now.map((shape, index) => {
        return (
          <div key={index} className="polygonContainer">
            <div>Polygon {index + 1}</div>
            <div>{shape?.geojson?.properties?.area?.toFixed(2)}</div>
          </div>
        );
      })}
      <Button
        type="submit"
        fullWidth
        disabled={now.length === 0}
        variant="contained"
        onClick={onSubmit}
        sx={{ mt: 3, mb: 2 }}
      >
        {/* {isLoading ? <CircularProgress size={24} /> : "Log In"} */}
        Confirm
      </Button>
    </div>
  );
};

export default CreateParcelForm;
