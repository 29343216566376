import React from "react";
import IndividualCrewMemberTimeline from "../../components/Visjs/IndividualCrewTimeline";
import EquipmentNeeded from "./EquipmentNeeded";
import { useParams } from "react-router-dom";
import { convertTime, formatDistance } from "../../utils/common";
import CrewMemberMap from "./CrewMemberMap";
import { useSelector } from "react-redux";

const CrewMemberDetailsDayWise = ({ crewMemberDayWise, crewMember }) => {
  const params = useParams();
  const mapData = useSelector((state) => state.global.mapData);
  const lookUp = useSelector((state) => state.global.lookUpData);
  const convertCrewMemberDayWise = (day, id) => {
    if (typeof day !== "number" || day < 1 || day > 7) {
      return;
    }
    const crewMemberData = crewMemberDayWise[id];

    if (!crewMemberData || !crewMemberData.day) {
      console.error("Crew member data or day data is not available.");
      return;
    }

    const dayData = crewMemberData.day.find((d) => d.day === day);

    if (!dayData) {
      console.error(`No data found for day ${day}.`);
      return;
    }

    return {
      [id]: {
        ...crewMemberData, // Spread operator to copy existing properties
        day: [dayData], // Only include the matching day's data
      },
    };
  };

  function calculateTotalAssignmentsForCrewMember(crewMemberDayWise) {
    const result = {};
    for (const crewId in crewMemberDayWise) {
      let crewMember = crewMemberDayWise[crewId];
      let totalAssignTravelTime = 0;
      let totalAssignTimeOfWork = 0;
      let totalAssignTravelDistance = 0;

      for (const day of crewMember.day) {
        for (const polygonId of day.polygon) {
          const polygon = lookUp.polygons[polygonId];
          if (polygon && polygon.crewMemberId == crewId) {
            totalAssignTravelTime += polygon.travelTime;
            totalAssignTimeOfWork += polygon.duration;
            totalAssignTravelDistance += polygon.travelDistance;
          }
        }
      }
      result.totalAssignTravelTime = totalAssignTravelTime;
      result.totalAssignTimeOfWork = totalAssignTimeOfWork;
      result.totalAssignTravelDistance = totalAssignTravelDistance;
    }

    return result;
  }
  const getServiceName = (serviceId) => {
    const servicesData = lookUp?.services;
    if (servicesData[serviceId]) {
      return servicesData[serviceId].serviceName;
    } else {
      return "Service not found";
    }
  };
  function calculateSkillUsed(crewMemberDayWise) {
    const result = [];
    for (const crewId in crewMemberDayWise) {
      let crewMember = crewMemberDayWise[crewId];

      for (const day of crewMember.day) {
        for (const polygonId of day.polygon) {
          const polygon = lookUp.polygons[polygonId];
          const serviceName = getServiceName(polygon?.serviceId).replace(
            " ",
            "_"
          );
          if (polygon && polygon.crewMemberId == crewId) {
            if (!result.includes(serviceName)) {
              result.push(serviceName);
            }
          }
        }
      }
    }

    return result;
  }
  return (
    <div>
      {crewMemberDayWise &&
        Object.values(crewMemberDayWise)[0]?.day?.map((dayData) => {
          const crewMemberDayWiseForMap = convertCrewMemberDayWise(
            dayData.day,
            params.id
          );
          const summaryData = calculateTotalAssignmentsForCrewMember(
            crewMemberDayWiseForMap
          );
          const skillsUsed = calculateSkillUsed(crewMemberDayWiseForMap);
          return (
            <div className="dayWise">
              <div>
                <h2>Day - {dayData.day}</h2>
                <IndividualCrewMemberTimeline
                  currentCrewMemberData={crewMember}
                  currentDay={dayData?.day}
                />
              </div>
              <div className="equipmentNeeded">
                <h2>Equipment Needed</h2>
                <EquipmentNeeded
                  currentDay={dayData?.day}
                  crewMember={crewMember}
                />
              </div>
              <div className="summaryAndMapContainer">
                <div className="summaryContainer">
                  <h2>Summary</h2>
                  <div className="summaryDetails">
                    <div className="flexContainer">
                      <div className="itemTitle">Employee </div>
                      <div className="itemContent">{crewMember.name}</div>
                    </div>
                    <div className="flexContainer">
                      <div className="itemTitle">Employee Id </div>
                      <div className="itemContent">{params.id}</div>
                    </div>
                    <div className="flexContainer">
                      <div className="itemTitle">Crew Id </div>
                      <div className="itemContent">{crewMember.crewId}</div>
                    </div>
                    <div className="flexContainer">
                      <div className="itemTitle">Work Time </div>
                      <div className="itemContent">
                        {convertTime(summaryData.totalAssignTimeOfWork)}
                      </div>
                    </div>
                    <div className="flexContainer">
                      <div className="itemTitle">Travel Time </div>
                      <div className="itemContent">
                        {convertTime(summaryData.totalAssignTravelTime)}
                      </div>
                    </div>
                    <div className="flexContainer">
                      <div className="itemTitle">Travel Distance </div>
                      <div className="itemContent">
                        {formatDistance(summaryData.totalAssignTravelDistance)}
                      </div>
                    </div>
                    <div className="flexContainer">
                      <div className="itemTitle">Skills </div>
                      <div className="itemContent">
                        {crewMember.skills &&
                          crewMember.skills.map((skill, index) => (
                            <span key={index}>
                              {skill}
                              {index < crewMember.skills.length - 1 ? ", " : ""}
                            </span>
                          ))}
                      </div>
                    </div>
                    <div className="flexContainer">
                      <div className="itemTitle">Skills Used </div>
                      <div className="itemContent">
                        {skillsUsed &&
                          skillsUsed.map((skill, index) => (
                            <span key={index}>
                              {skill?.replace("_", " ")}
                              {index < skillsUsed.length - 1 ? ", " : ""}
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mapContainer">
                  <h2>Activity Zones</h2>
                  {crewMemberDayWise && (
                    <div className="mapboxContainer">
                      <CrewMemberMap
                        mapData={mapData}
                        crewMemberDayWise={crewMemberDayWiseForMap}
                        polygonsData={lookUp?.polygons}
                        currentPathIds={dayData.polygon}
                        currentCrewMemberId={params.id}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CrewMemberDetailsDayWise;
