import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { config } from "./config";

export const rtkBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().global.token;
    if (token) {
      headers.set("Authorization", `JWT ${token}`);
    }
    return headers;
  },
});


export const openBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL_OPEN,
});
export const testingBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL_TESTING,
});