import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
  } from "@mui/material";
  import React from "react";
  import { IoMdClose } from "react-icons/io";
  
  const ConfirmationModal = ({ isOpen, setIsOpen, title, body, handleOk }) => {
    const handleCancel = () => {
      setIsOpen(false);
    };
    return (
      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <DialogTitle fontWeight={700} fontSize="22px" align="left">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoMdClose />
        </IconButton>
        <div className="confirmationModalBody">
          <div>{body}</div>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleOk}>
              Yes
            </Button>
          </Box>
        </div>
      </Dialog>
    );
  };
  
  export default ConfirmationModal;
  