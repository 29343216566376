import React, { useState } from "react";
import {
  Tab,
  Tabs,
} from "@mui/material";
import "./Properties.css";
import { TabContext, TabPanel } from "@mui/lab";
import ParcelTable from "./Components/ParcelTable";
import SavedPlans from "./Components/SavedPlans";

const Properties = () => {
  const [tabValue, setTabValue] = useState("1");


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="propertiesContainer">
      <TabContext value={tabValue}>
        <Tabs
          value={tabValue}
          fontSize={16}
          onChange={handleTabChange}
          centered
        >
          <Tab label="Saved Plans" value="1" />
          <Tab label="Properties" value="2" />
        </Tabs>
        <TabPanel value="1"><SavedPlans/></TabPanel>
        <TabPanel value="2"><ParcelTable/></TabPanel>
      </TabContext>
     
    </div>
  );
};

export default Properties;
