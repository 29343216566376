import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/constants";
import { Button } from "@mui/material";
import "./NotFound.css"

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="notFound">
      <div className="notfound-404">
        <h1>Oops!</h1>
      </div>
      <h2>404 - Page not found</h2>
      <p>
        The page you are looking for might have been removed had its name
        changed or is temporarily unavailable.
      </p>
      <Button
        className="button"
        onClick={() => navigate(ROUTES.PROPERTIES)}
      >
        Go To Home
      </Button>
    </div>
  );
};

export default NotFound;
