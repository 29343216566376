export const ROUTES = {
    LOGIN: "/login",
    REGISTER: "/register",
    MAP: "/map",
    CREWMEMBERDETAILS: "/crewmemberdetails/:id",
    CREWCREATION: "/createCrew",
    GOOGLEMAPS: "/",
    SURVEY: "/survey",
    NEWUSER: "/newuser",
    PROPERTIES: "/properties",
    PARCEL: "/parcel/:id",
    VERIFYEMAIL: "/verifyemail/:id",
}

export const APIENDPOINTS = {
    LOGIN: "v3/login",
    REGISTER: "v3/register",
    VERIFYEMAIL: "v3/verify-email",
    ORGANIZATION: "v3/organizations",
    REFRESHTOKEN: "v3/refresh-jwt-token",  
}