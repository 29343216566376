import React, { useEffect, useState } from "react";
import {
  useAddStepServicesDataMutation,
  useLazyGetServicesDataQuery,
  useLazyGetStepServicesDataQuery,
} from "../../../services/OpenApi";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import AddServicesAndEquipmentModal from "../../../Modals/AddServicesAndEquipmentModal/AddServicesAndEquipmentModal";
import { useParams } from "react-router-dom";
import { handleNextStep } from "../../../slices/stepperFormSlice";
import { toast } from "react-toastify";

const AddServicesAndEquipmentForm2 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const params = useParams();
  const dispatch = useDispatch();
  const [addStepServicesData] = useAddStepServicesDataMutation();
  const [getStepServicesData] = useLazyGetStepServicesDataQuery();
  const user = useSelector((state) => state.global.user);
  const [getServicesData, { isLoading }] = useLazyGetServicesDataQuery();
  const [skillAndEquipmentDropdown, setSkillsAndEquipentDropdown] = useState(
    []
  );
  const [servicesDefaultData, setServicesDefaultData] = useState([
    {
      serviceSkill: {
        skillName: "Mowing",
        skillId: 1,
      },
      name: "Turf",
      serviceEquipment: [
        {
          productionRate: 90000,
          transitionPace: 79200,
          equipmentName: "72 Inch Riding Mower",
          equipmentId: 1,
        },
      ],
      description: "Turf",
      serviceId: 1,
    },
    {
      serviceSkill: {
        skillName: "Hard Edging",
        skillId: 2,
      },
      name: "Hard Edge",
      serviceEquipment: [
        {
          productionRate: 8000,
          transitionPace: 10000,
          equipmentName: "Hard Edger",
          equipmentId: 5,
        },
      ],
      description: "Hard Edge",
      serviceId: 4,
    },
    {
      serviceSkill: {
        skillName: "General Skills",
        skillId: 3,
      },
      name: "String Trimmer",
      serviceEquipment: [
        {
          productionRate: 4000,
          transitionPace: 10000,
          equipmentName: "String Trimmer",
          equipmentId: 6,
        },
      ],
      description: "String Trimmer",
      serviceId: 5,
    },
    {
      serviceSkill: {
        skillName: "General Skills",
        skillId: 3,
      },
      name: "Cleanup Blowing",
      serviceEquipment: [
        {
          productionRate: 9000,
          transitionPace: 10000,
          equipmentName: "Backpack Blower",
          equipmentId: 7,
        },
      ],
      description: "Cleanup Blowing",
      serviceId: 6,
    },
  ]);

  const {
    stepperForm: { activeStep, stepsLabels },
  } = useSelector((state) => state);
  useEffect(() => {
    (async () => {
      const response = await getServicesData();
      setSkillsAndEquipentDropdown(response?.data?.data);
      // setDefaultServicesData(response?.data?.data);
    })();
  }, []);
  const editServiceClick = (service) => {
    setIsOpen(true);
    setEditData(service);
  };

  const onSubmit = async () => {
    const data = {
      orderId: params?.id,
      orgName: user?.organization,
      content: servicesDefaultData.map(service => {
        return {
            serviceId: service.serviceId,
            skillId: service.serviceSkill.skillId,
            serviceEquipment: service.serviceEquipment.map(equipment => {
                return {
                    equipmentId: equipment.equipmentId,
                    productionRate: equipment.productionRate,
                    transitionPace: equipment.transitionPace,
                    measuringUnit: equipment?.measuringUnit ? equipment?.measuringUnit : 0  // You may replace 0 with the appropriate measuring unit value
                };
            })
        };
    })
    };

    console.log("transformed data ==>> ",data)
    try{
        const response = await addStepServicesData(data);
        console.log("response ==>> ",response)
        if(response){
            toast.success("Services successfully", {
                autoClose: 1000,
                hideProgressBar: true,
              });
              dispatch(handleNextStep());
        }
    }catch(err){
        console.log(err)
    }

    const content = [];
  };

  const UpdateServiceData = (data) => {
    const updatedData = servicesDefaultData.map((service) => {
      if (service?.serviceId === data?.serviceId) {
        return data;
      }
      return service;
    });
    setServicesDefaultData(updatedData);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getStepServicesData({
          orderId: params?.id,
          orgName: user?.organization,
        }).unwrap();
        if (response) {
          console.log("response ==>> ",response)
          if(response.data.length>0){
            setServicesDefaultData(response.data)
          }
        }
      } catch (err) {
        console.log("error => ", err);
      }
    })();
  }, [getStepServicesData]);
  return (
    <div className="StepsFormContainer">
      <h3 className="StepsFormContainerHeading">Add Services</h3>
      {servicesDefaultData &&
        servicesDefaultData?.map((service) => {
          return (
            <div className="serviceContainer" key={service?.id}>
              <div className="serviceName">
                {service?.name}{" "}
                <MdEdit
                  cursor="pointer"
                  onClick={() => editServiceClick(service)}
                  fontSize="14"
                />
              </div>
              <div className="serviceSubTitle">Skill</div>
              <Typography component="h3" marginBottom="10px" variant="body2">
                {service?.serviceSkill?.skillName}
              </Typography>
              {service?.serviceEquipment?.map((equipment) => {
                return (
                  <div className="equipmentDataContainer">
                    <div className="equipmentDataLeft">
                      <div className="serviceSubTitle">Equipment Name</div>
                      <Typography component="h3" variant="body2">
                        {equipment?.equipmentName}
                      </Typography>
                    </div>
                    <div className="equipmentDataRight">
                      <div className="serviceSubTitle">Production Rate</div>
                      <Typography component="h3" variant="body2">
                        {equipment?.productionRate}
                      </Typography>
                    </div>
                    <div className="equipmentDataRight">
                      <div className="serviceSubTitle">Transition Pace</div>
                      <Typography component="h3" variant="body2">
                        {equipment?.transitionPace}
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      <AddServicesAndEquipmentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        editData={editData}
        UpdateServiceData={UpdateServiceData}
        skillAndEquipmentDropdown={skillAndEquipmentDropdown}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        onClick={onSubmit}
        sx={{ mt: 3, mb: 2 }}
      >
        {/* {isLoading ? <CircularProgress size={24} /> : "Log In"} */}
        Confirm
      </Button>
    </div>
  );
};

export default AddServicesAndEquipmentForm2;
