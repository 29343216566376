import React, { useEffect, useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  reset as resetGlobal,
  setAnimationData,
  setRefreshTokenTimer,
} from "../../slices/globalSlice";
import { reset as resetWorkPlan } from "../../slices/workPlanSlice";
import animate from "../../files/BDTechnologies-2-Service-ST.animation.json";
import { assignCrewMemberColorsToAnimation } from "../../utils/common";
import { Box, Button } from "@mui/material";
import SecondaryButton from "../Buttons/SecondaryButton";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.global.user);
  const animationData = useSelector((state) => state.global.animationData);

  useEffect(() => {
    const animateDataToSet = animationData || animate;
    const animatedData = assignCrewMemberColorsToAnimation(animateDataToSet);
    dispatch(setAnimationData(animatedData));
  }, []);

  const handleLogOut = () => {
    dispatch(resetGlobal());
    dispatch(resetWorkPlan());
    toast.success("logged out successfully", {
      autoClose: 1000,
      hideProgressBar: true,
    });
    localStorage.clear();
    navigate("/login");
    dispatch(setRefreshTokenTimer(null));
  };

  return (
    <Box className="headerContainer" backgroundColor={user ? "primary.main": "white"}>
      <div className="logoContainer" onClick={() => navigate("/")}>
        <img src={user ? "/images/whiteLogo.png" : "/images/logo.png" } width="120px" height="25px" />
      </div>
      {user && <div className="menuContainer">
        <SecondaryButton text="Log Out" variant="contained" background="white" textColor="primary.main" onClick={handleLogOut}>
          Log Out
        </SecondaryButton>
      </div>}
      
    </Box>
  );
};

export default Header;
