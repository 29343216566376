import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  CircularProgress,
  Stack,
  Skeleton,
} from "@mui/material";
// import { Folder, ExpandLess, ExpandMore } from '@mui/icons-material';
import axios from "axios";
import { setWorkerPlanFolderData } from "../../../slices/workPlanSlice";
import { useGetWorkPlansMutation } from "../../../services/Properties";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FaRegFileAlt } from "react-icons/fa";
import { RotateLoader } from "react-spinners";

const NestedFolderList = ({ folderPath, onFolderClick }) => {
  const user = useSelector((state) => state.global.user);
  const workerPlanFolderData = useSelector(
    (state) => state.workPlan.workerPlanFolderData
  );
  const [getWorkPlans, { isLoading }] = useGetWorkPlansMutation();
  const dispatch = useDispatch();

  const fetchWorkPlans = async () => {
    const data = {
      organization: user.organization,
      locationGroup: folderPath,
    };
    try {
      const workDataFolder = await getWorkPlans(data).unwrap();
      dispatch(setWorkerPlanFolderData(workDataFolder.results));
    } catch (error) {
      toast.error(
        error?.originalStatus === 500 ? "Something Went Wrong" : error
      );
    }
  };

  useEffect(() => {
    if (folderPath) {
      fetchWorkPlans();
    }
  }, [folderPath]);

  const handlePlanClick = (key) => {
    const baseUrl = window.location.origin;
    const newUrl = `${baseUrl}/map?location=${folderPath}&workFolder=${key}`;
    window.open(newUrl, "_blank");
  };

  if (isLoading) {
    return (
        <>
      <Stack direction={"row"} padding={"16px"} spacing={5}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="rectangular" width={30} height={30} />
        <Skeleton variant="rectangular" width={210} height={30} />
      </Stack>
      <Stack direction={"row"} padding={"16px"} spacing={5}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="rectangular" width={30} height={30} />
        <Skeleton variant="rectangular" width={210} height={30} />
      </Stack>
      <Stack direction={"row"} padding={"16px"} spacing={5}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="rectangular" width={30} height={30} />
        <Skeleton variant="rectangular" width={210} height={30} />
      </Stack>
      <Stack direction={"row"} padding={"16px"} spacing={5}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="rectangular" width={30} height={30} />
        <Skeleton variant="rectangular" width={210} height={30} />
      </Stack>
        </>
    );
  }

  return (
    <List>
      {workerPlanFolderData &&
        workerPlanFolderData.map((folder) => (
          <ListItem
            button
            key={folder.name}
            onClick={() => handlePlanClick(folder.key)}
          >
            <ListItemIcon>
              <FaRegFileAlt />
            </ListItemIcon>
            <ListItemText primary={folder.name} />
          </ListItem>
        ))}
    </List>
  );
};

export default NestedFolderList;
