import { AdvancedMarker } from '@vis.gl/react-google-maps';
import React from 'react'
import Pin from '../../components/Mapbox/Pin';

const ParkingsCoordinatesMarker = ({parkings}) => {
  return (
    parkings.map((parkingdata,Index) => {
          return (
            <div style={{ zIndex: 2 }} key={parkingdata.parkingId}>
              <AdvancedMarker
              position={{lat: parkingdata?.points?.lat, lng: parkingdata?.points?.lon}}
                zIndex={2}
                  
              //   onClick={(e) => {
              //     e.originalEvent.stopPropagation();
              //     setSelectedParking({
              //       ...parking,
              //       parkingId:parkingId
              //     });
              //   }}
              >
                <Pin text="P" color="#ffa600"></Pin>
              </AdvancedMarker>
            </div>
          );
        })
  )
}

export default ParkingsCoordinatesMarker