import { Button } from "@mui/material";
import React from "react";

const SecondaryButton = (props) => {
  const { background, textColor, text, marginTop, marginBottom, marginLeft, marginRight } = props;
  return (
    <Button
      {...props}
      sx={{
        background: background ? background : "#E0FFC4",
        hover: {
          background: background ? background : "#E0FFC4",
        },
        color: textColor ? textColor : "#3F8203",
        boxShadow: "none",
        marginTop: marginTop ? marginTop : "0",
        marginBottom: marginBottom ? marginBottom : "0",
        marginLeft: marginLeft ? marginLeft : "0",
        marginRight: marginRight ? marginRight : "0",
      }}
    >
      {text}
    </Button>
  );
};

export default SecondaryButton;

