import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

const AddServicesAndEquipmentModal = ({
  isOpen,
  setIsOpen,
  UpdateServiceData,
  editData,
  skillAndEquipmentDropdown,
}) => {
  const [formData, setFormData] = useState(null);

  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setFormData(editData ? editData : null);
  }, [editData, isOpen]);

  const handleAddOrUpdateCrewEquipment = () => {
    UpdateServiceData(formData);
    setIsOpen(false);
  };

  const currentService = skillAndEquipmentDropdown?.find(
    (data) => data?.name === editData?.name
  );

  const handleSkillChange = (event) => {
    const selectedSkill = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      serviceSkill: {
        ...prevData.serviceSkill,
        skillName: selectedSkill,
      },
    }));
  };

  const handleEquipmentChange = (index, value) => {
    const selectedEquipment = currentService?.equipment?.find(
      (equipment) => equipment.equipmentName === value
    );
    const updatedEquipment = [...formData.serviceEquipment];
    updatedEquipment[index] = selectedEquipment;
    setFormData((prevData) => ({
      ...prevData,
      serviceEquipment: updatedEquipment,
    }));
  };

  const handleFieldChange = (index, field, value) => {
    setFormData((prevData) => {
      const updatedEquipment = [...prevData.serviceEquipment];
      updatedEquipment[index] = {
        ...updatedEquipment[index],
        [field]: parseInt(value),
      };
      return {
        ...prevData,
        serviceEquipment: updatedEquipment,
      };
    });
  };

  const handleAddEquipment = () => {
    if (formData.serviceEquipment.length < 2) {
      const newEquipment = { ...formData.serviceEquipment[0] }; // Clone the first equipment
      setFormData((prevData) => ({
        ...prevData,
        serviceEquipment: [...prevData.serviceEquipment, newEquipment],
      }));
    }
  };

  const handleRemoveEquipment = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      serviceEquipment: prevData.serviceEquipment.filter((_, i) => i !== index),
    }));
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle fontWeight={700} fontSize="24px" align="left">
        {editData ? "Update Service" : "Add Service"}
      </DialogTitle>
      <Divider />
      <div className="dialogContainer">
        <form>
          <div className="nameSkillsContainer">
            <div>
              <div className="serviceSubTitle">Skill</div>
              <Select
                size="small"
                fullWidth
                margin="normal"
                value={formData?.serviceSkill?.skillName || ""}
                onChange={handleSkillChange}
              >
                <MenuItem value={currentService?.skill?.skillName}>
                  {currentService?.skill?.skillName}
                </MenuItem>
              </Select>
              <div className="serviceSubTitle1">Equipment</div>
              {formData?.serviceEquipment?.map((equipment, index) => {
                return (
                  <>
                    <div className="equipmentDataContainer">
                      <div className="equipmentDataLeft">
                        <div className="serviceSubTitle">Name</div>
                        <Select
                          size="small"
                          fullWidth
                          margin="normal"
                          value={equipment?.equipmentName || ""}
                          onChange={(event) =>
                            handleEquipmentChange(index, event.target.value)
                          }
                        >
                          {currentService?.equipment?.map((equipment) => (
                            <MenuItem value={equipment?.equipmentName}>
                              {equipment?.equipmentName}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className="equipmentDataRight">
                        <div className="serviceSubTitle">Production Rate</div>
                        <TextField
                          required
                          name="crewMemberName"
                          value={equipment?.productionRate}
                          onChange={(event) =>
                            handleFieldChange(
                              index,
                              "productionRate",
                              event.target.value
                            )
                          }
                          size="small"
                        />
                      </div>
                      <div className="equipmentDataRight">
                        <div className="serviceSubTitle">Transition Pace</div>
                        <TextField
                          required
                          name="crewMemberName"
                          value={equipment?.transitionPace}
                          onChange={(event) =>
                            handleFieldChange(
                              index,
                              "transitionPace",
                              event.target.value
                            )
                          }
                          size="small"
                        />
                      </div>
                    </div>
                    {index > 0 && (
                      <Button onClick={() => handleRemoveEquipment(index)}>
                        Remove
                      </Button>
                    )}
                  </>
                );
              })}
              {formData?.name === "Turf" &&
                formData.serviceEquipment.length < 2 && (
                  <Button onClick={handleAddEquipment}>Add Equipment</Button>
                )}
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleAddOrUpdateCrewEquipment}
            >
              {editData ? "Update" : "Add"}
            </Button>
          </Box>
        </form>
      </div>
    </Dialog>
  );
};

export default AddServicesAndEquipmentModal;
