import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { FaFolder } from 'react-icons/fa';

const FolderList = ({ onFolderClick }) => {
const locationGroupData = useSelector(
    (state) => state.workPlan.locationGroupData
  );

  return (
    <List>
      {locationGroupData && locationGroupData.map(folder => (
        <ListItem button key={folder.name} onClick={() => onFolderClick(folder.key)}>
          <ListItemIcon>
          <FaFolder />
          </ListItemIcon>
          <ListItemText primary={folder.name} />
        </ListItem>
      ))}
    </List>
  );
};

export default FolderList;
