import "./App.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppRoutes from "./Routes/AppRoutes";
import {
  setAnimationData,
  setInitialCrewMembers,
  setIsLogin,
  setLandingPageMap,
  setLookUpData,
  setMapData,
  setToken,
  setUser,
} from "./slices/globalSlice";
import {
  setIsLocationSet,
  setLocationGroupData,
  setSelectedLocation,
  setSelectedServiceType,
  setWorkerPlanFolderData,
} from "./slices/workPlanSlice";
import { fetchS3Data } from "./Modals/LocationGroupModal/LocationGroupModal";
import {
  ShuffleJson,
  assignCrewMemberColors,
  assignCrewMemberColorsToAnimation,
  calculateServicesTotal,
  calculateTotalAssignmentsForCrewMember,
} from "./utils/common";
import { useGetWorkPlansMutation } from "./services/Properties";
import { toast } from "react-toastify";
import { generateRoutePlanForAnimation } from "./utils/routePlanforAnimation";
import { useTokenRefresh } from "./utils/refreshTokenUtils";

function App() {
  const isToken = localStorage.getItem("accessToken");
  const currentLocation = localStorage.getItem("current location");
  const currentWorkFolder = localStorage.getItem("current folder");
  const user = localStorage.getItem("user");
  const [getWorkPlans, { isLoading }] = useGetWorkPlansMutation();
  const token = useSelector((state) => state.global.token);
  const mapData = useSelector((state) => state.global.mapData);
  const lookUpData = useSelector((state) => state.global.lookUpData);
  const dispatch = useDispatch();
  useTokenRefresh(token);

  useEffect(() => {
    if (currentLocation && user) {
      dispatch(setIsLocationSet(true));
      const fetchWorkPlans = async () => {
        const currentUser = JSON.parse(user);
        const location = JSON.parse(currentLocation);
        const workFolder = JSON.parse(currentWorkFolder);
        const data = {
          organization: currentUser?.organization,
          locationGroup: location.key,
        };
        try{

          const workDataFolder = await getWorkPlans(data).unwrap();
          dispatch(setWorkerPlanFolderData(workDataFolder?.results));
          const currentFolder = workDataFolder?.results?.filter(
            (item) => item.key == workFolder.key
          );
          const MapScreenGeoJson = currentFolder[0]?.files?.filter(
            (item) => item.file_type == "site_plan_map"
          );
          const LookupFile = currentFolder[0]?.files?.filter(
            (item) => item.file_type == "planner_output_lookup"
          );

          const animationFile = currentFolder[0]?.files?.filter(
            (item) => item.file_type == "planner_animation"
          );

          if (animationFile?.length > 0) {
            fetchS3Data(animationFile[0]?.file_s3_url).then((data) => {
              const animatedData = assignCrewMemberColorsToAnimation(data);
              if (animatedData.hasOwnProperty("0")) {
                delete animatedData["0"];
              }
              dispatch(setAnimationData(animatedData));
            });
          }

          dispatch(setSelectedLocation(location));
          dispatch(setSelectedServiceType(workFolder));
          fetchS3Data(MapScreenGeoJson[0]?.file_s3_url)
            .then((data) => {
              ShuffleJson(data);
              dispatch(setMapData(data));
              dispatch(setLandingPageMap(data));
            })
            .catch((error) => {
              toast.error(error?.error)
            });
          fetchS3Data(LookupFile[0]?.file_s3_url)
            .then((data) => {
              const fileData = data?.lookUpOutput ? data?.lookUpOutput : data;
              const final = calculateTotalAssignmentsForCrewMember(fileData);
              const withServices = calculateServicesTotal(final);
              const crewColors = assignCrewMemberColors(withServices);
              dispatch(setLookUpData(crewColors));
              dispatch(setInitialCrewMembers(withServices?.crewMemberDayWise));
            })
            .catch((error) => {
              toast.error("Uploaded Lookup Files is having wrong format", {
                autoClose: 2000,
                hideProgressBar: true,
              });
            });
          }
          catch(error) {
            toast.error(error?.error)
          }
      };
      fetchWorkPlans();
    }
  }, [currentLocation, user]);

  useEffect(() => {
    if (user) {
      dispatch(setUser(JSON.parse(user)));
    }
  }, []);

  useEffect(() => {
    if(mapData){
      const routePlan = generateRoutePlanForAnimation(lookUpData, mapData)
      dispatch(setLookUpData(routePlan));
    }
  },[mapData])

  useEffect(() => {
    const retrievedData = localStorage.getItem("locationGroups");
    dispatch(setLocationGroupData(JSON.parse(retrievedData)));
  }, []);

  if (isToken && !token) {
    dispatch(setToken(isToken));
    dispatch(setIsLogin(true));
  }
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
