import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateOrganizationMutation } from "../../services/Auth";
import { useNavigate } from "react-router-dom";
import { getCustomColor } from "../../utils/theme";

const NewUser = () => {
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      country: "", // Placeholder for the select field
      pincode: "",
    },
  });
  const navigate = useNavigate();
  const blackShade1 = getCustomColor("blackShade1");
  const user = JSON.parse(localStorage.getItem("user"));
  const [createOrganization, { isLoading }] = useCreateOrganizationMutation();

  const onSubmit = async (data) => {
    try {
      const domain = user?.email?.split("@")[1].split(".")[0];
      const apiKey = "AIzaSyDVIMgTN3Ul0B78ZnXbj_tb37j8BjzzrFY";
      const url = `https://maps.googleapis.com/maps/api/geocode/json?components=country:${data?.country}|postal_code:${data?.pincode}&key=${apiKey}`;

      const response = await fetch(url);
      const dataResponse = await response.json();

      if (dataResponse.results.length === 0) {
        toast.error("Invalid Pincode");
        return;
      }

      const locality = dataResponse.results[0]?.address_components?.find(
        (component) =>
          component?.types?.includes("locality") && component?.long_name
      );

      const formattedArea = `${domain}-${locality?.long_name
        ?.replace(/\s+/g, "_")
        ?.toLowerCase()}`;

      const postData = {
        name: formattedArea,
        key: formattedArea,
      };

      try {
        await createOrganization(postData).unwrap();
        console.log("response ==>> ", response);
        toast.success("Organization created successfully");
        navigate("/properties");
      } catch (error) {
        toast.error("Error creating organization");
      }

      console.log("data ==>> ", postData);
    } catch (error) {
      console.error("Error fetching pin code details:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="authContainer"
      >
        <Typography
          fontWeight={"600"}
          sx={{ color: blackShade1.main }}
          component="h3"
          variant="h5"
        >
          Create your organization
        </Typography>
        <Box
          component="form"
          width="100%"
          noValidate
          onSubmit={handleFormSubmit(onSubmit)}
          sx={{ mt: 1 }}
        >
          <div className="formFields">
            <Typography
              fontWeight={"700"}
              sx={{ color: blackShade1.light }}
              component="body1"
              variant="body1"
            >
              Country
            </Typography>
            <Controller
              name="country"
              control={control}
              rules={{ required: "Please select a country" }}
              render={({ field }) => (
                <Select
                  id="country"
                  size="small"
                  fullWidth
                  {...field}
                  displayEmpty
                  error={!!errors.country}
                  sx={{
                    '& .MuiSelect-select:focus': {
                      backgroundColor: 'inherit',
                    },
                    '& .MuiSelect-select': {
                      color: field.value ? 'inherit' : 'rgba(60, 60, 60, 0.54)',
                    },
                  }}
                >
                  <MenuItem value="" disabled sx={{ color: 'rgba(60, 60, 60, 0.54)' }}>
                    Select Country
                  </MenuItem>
                  <MenuItem value="US">United States of America</MenuItem>
                  <MenuItem value="AU">Australia</MenuItem>
                </Select>
              )}
            />
          </div>
          <Typography color="red" marginLeft="13px" fontSize="12.5px">
            {errors.country ? errors.country.message : ""}
          </Typography>
          <div className="formFields">
            <Typography
              fontWeight={"700"}
              sx={{ color: blackShade1.light }}
              component="body1"
              variant="body1"
            >
              Pincode
            </Typography>
            <TextField
              fullWidth
              size="small"
              id="pincode"
              name="pincode"
              placeholder="Enter the Pincode / zip code your property is in "
              {...register("pincode", {
                required: "Pincode is required",
              })}
              error={!!errors.pincode}
              helperText={errors.pincode ? errors.pincode.message : ""}
            />
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 4, mb: 2, boxShadow: "none" }}
          >
            Create Organization
          </Button>
        </Box>
      </Box>
      <div className="patternLeft">
        <img src="/images/patternsLeft.png" />
      </div>
      <div className="patternRight">
        <img src="/images/patternsRight.png" />
      </div>
    </>
  );
};

export default NewUser;
