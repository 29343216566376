import React, { useEffect, useState } from "react";
import {
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLazyGetOrderQuery } from "../../../services/OpenApi";
import { StyledTableCell } from "../../MapScreen/PlannerDetailsDrawer";

const ParcelTable = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [allOrders, setAllOrders] = useState([]);
  const [getOrder, { isLoading }] = useLazyGetOrderQuery();
  useEffect(() => {
    (async () => {
      if (user?.organization) {
        try {
          const response = await getOrder({ orgName: user?.organization });
          setAllOrders(response?.data?.data);
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, []);

  const handleAddressClick = (id) => {
    const baseUrl = window.location.origin;
    const newUrl = `${baseUrl}/parcel/${id}`;
    window.open(newUrl, "_blank");
  };
  if (isLoading) return <div>isLoading</div>;

  return (
    <div style={{ height: 400, width: "100%" }}>
      <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allOrders?.length > 0 &&
              allOrders.map((row) => (
                <TableRow key={row.id}>
                  <StyledTableCell>
                    <span
                      className="tableParcelTitle"
                      onClick={() => handleAddressClick(row.id)}
                    >
                      {row.address}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell>{row.status}</StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ParcelTable;
