import React from "react";

function ArrowHead({ color = "#3FB1CE", opacity = 1.0, angle = "270" }) {
  const pinStyle = {
    display: opacity === 0 ? "none" : "block",
    opacity: opacity,
  };
  const rotate = `rotate(${82 - angle})`;
  return (
    <svg
      fill={color}
      height="16px"
      width="16px"
      viewBox="0 0 20 20"
      transform={rotate}
      style={pinStyle}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Layer 2">
        <g data-name="navigation">
          <rect width="26" height="26" opacity="0" />
          <path d="M20 20a.94.94 0 0 1-.55-.17l-6.9-4.56a1 1 0 0 0-1.1 0l-6.9 4.56a1 1 0 0 1-1.44-1.28l8-16a1 1 0 0 1 1.78 0l8 16a1 1 0 0 1-.23 1.2A1 1 0 0 1 20 20z" />
        </g>
      </g>
    </svg>
  );
}

export default React.memo(ArrowHead);
