import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  isPremium: false,
  user: null,
  token: null,
  refreshTokenTimer: null,
  landingPageMap: null,
  mapData: null,
  isStreetView: false,
  mapStyle: "mapbox://styles/mapbox/light-v11",
  animationData: null,
  resetAnimation: false,
  selectedDay: 1,
  lookUpData: null,
  initialCrewMembers: null,
  crewMemberDayWiseFilteredData: null,
  selectedCrewMembersId: null,
  selectedCrewMembersPathId: null,
  geoJsonFilteredData: null,
  selectedPolygon: null,
  selectedServices: {},
  serviceWise: false,
  showNumberCircles: false,
  showArrowMarkers: false,
  showConnectingLines: false,
  showObstacles: false
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    reset: () => initialState,
    setIsLogin: (state, action) => {
      return {
        ...state,
        isLogin: action.payload,
      };
    },
    setIsPremium: (state, action) => {
      return {
        ...state,
        isPremium: action.payload,
      };
    },
    setRefreshTokenTimer: (state, action) => {
      return {
        ...state,
        refreshTokenTimer: action.payload,
      };
    },
    setToken: (state, action) => {
      return {
        ...state,
        token: action.payload,
      };
    },
    setUser: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    setLandingPageMap: (state, action) => {
      return {
        ...state,
        landingPageMap: action.payload,
      };
    },
    setMapData: (state, action) => {
      return {
        ...state,
        mapData: action.payload,
      };
    },
    setIsStreetView: (state, action) => {
      return {
        ...state,
        isStreetView: action.payload,
      };
    },
    setMapStyle: (state, action) => {
      return {
        ...state,
        mapStyle: action.payload,
      };
    },
    setAnimationData: (state, action) => {
      return {
        ...state,
        animationData: action.payload,
      };
    },
    setResetAnimation: (state, action) => {
      return {
        ...state,
        resetAnimation: action.payload,
      };
    },
    setSelectedDay: (state, action) => {
      return {
        ...state,
        selectedDay: action.payload,
      };
    },
    setLookUpData: (state, action) => {
      return {
        ...state,
        lookUpData: action.payload,
      };
    },
    setInitialCrewMembers: (state, action) => {
      return {
        ...state,
        initialCrewMembers: action.payload,
      };
    },
    setCrewMemberDayWiseFilteredData: (state, action) => {
      return {
        ...state,
        crewMemberDayWiseFilteredData: action.payload,
      };
    },
    setSelectedCrewMembersId: (state, action) => {
      return {
        ...state,
        selectedCrewMembersId: action.payload,
      };
    },
    setSelectedCrewMembersPathId: (state, action) => {
      return {
        ...state,
        selectedCrewMembersPathId: action.payload,
      };
    },
    setGeoJsonFilteredData: (state, action) => {
      return {
        ...state,
        geoJsonFilteredData: action.payload,
      };
    },
    setSelectedPolygon: (state, action) => {
      return {
        ...state,
        selectedPolygon: action.payload,
      };
    },
    setSelectedServices: (state, action) => {
      if (typeof action.payload === "string") {
        const serviceType = action.payload;
        state.selectedServices[serviceType] =
          !state.selectedServices[serviceType];
      } else if (typeof action.payload === "object") {
        state.selectedServices = action.payload;
      }
    },
    setServiceWise: (state, action) => {
      return {
        ...state,
        serviceWise: action.payload,
      };
    },
    setShowNumberCircles: (state, action) => {
      return {
        ...state,
        showNumberCircles: action.payload,
      };
    },
    setShowArrowMarkers: (state, action) => {
      return {
        ...state,
        showArrowMarkers: action.payload,
      };
    },
    setShowConnectingLines: (state, action) => {
      return {
        ...state,
        showConnectingLines: action.payload,
      };
    },
    setShowObstacles: (state, action) => {
      return {
        ...state,
        showObstacles: action.payload,
      };
    },
  },
});

export const {
  reset,
  setIsLogin,
  setIsPremium,
  setRefreshTokenTimer,
  setToken,
  setUser,
  setLandingPageMap,
  setMapData,
  setIsStreetView,
  setMapStyle,
  setAnimationData,
  setResetAnimation,
  setSelectedDay,
  setLookUpData,
  setInitialCrewMembers,
  setCrewMemberDayWiseFilteredData,
  setSelectedCrewMembersId,
  setSelectedCrewMembersPathId,
  setGeoJsonFilteredData,
  setSelectedPolygon,
  setSelectedServices,
  setServiceWise,
  setShowNumberCircles,
  setShowArrowMarkers,
  setShowConnectingLines,
  setShowObstacles
} = globalSlice.actions;

export default globalSlice.reducer;
