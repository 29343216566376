import React, { useEffect, useState } from "react";
import { DeckGlOverlay } from "./deckgl-overlay";
import { GeoJsonLayer, PathLayer } from "@deck.gl/layers";
import { getPreviousAndNextLineData, getServiceColorRgb, hexToRgb } from "../../utils/common";

function getDeckGlLayers(
  prevLineData,
  nextLineData,
  wayPointsData,
  centerLine,
  showConnectingLines
) {
  return [
    new PathLayer({
      id: "dashed-path-layer-prev",
      data: prevLineData,
      getPath: (d) => [d.source, d.target], // Adjust according to your data structure
      getColor: [160,160,160],
      getWidth: 0.2,
      getDashArray: [3, 2],
      dashJustified: true,
      dashGapPickable: true,
      widthMinPixels: 4,
      visible: showConnectingLines ? true : false,
    }),
    new PathLayer({
      id: "dashed-path-layer-next",
      data: nextLineData,
      getPath: (d) => [d.source, d.target], // Adjust according to your data structure
      getColor: [47, 117, 137],
      getWidth: 0.2,
      getDashArray: [3, 2],
      dashJustified: true,
      dashGapPickable: true,
      widthMinPixels: 4,
      visible: showConnectingLines ? true : false,
    }),

    new GeoJsonLayer({
      id: "wayPointsLayer",
      data: wayPointsData,
      pointType: "circle+text",
      getPosition: (d) => d.geometry.coordinates,
      getFillColor: [255, 0, 0],
      getLineColor: [173, 5, 5],
      stroked: true,
      iconBillboard: true,
      getPointRadius: 1,
      pointSizeScale: 1,
      pointRadiusMaxPixels: 1,
      pickable: true,
    }),

    new GeoJsonLayer({
      id: "mapHoveredGeoJsonLayerCenter",
      data: centerLine,
      stroked: true,
      filled: true,
      getLineWidth: 0.5,
      lineWidthMinPixels: 1,
      opacity: 1,
      // getLineColor: [255, 0, 0],
      getLineColor: (f) => getServiceColorRgb(f?.properties?.serviceType)
    }),
  ];
}

function filterLineStrings(geoJson) {
  const filteredFeatures = geoJson.features.map((feature) => {
    if (feature.geometry && feature.geometry.type === "GeometryCollection") {
      const lineStrings = feature.geometry.geometries.filter(
        (geometry) => geometry.type === "LineString"
      );
      return {
        ...feature,
        geometry: {
          ...feature.geometry,
          geometries: lineStrings,
        },
      };
    }
    return feature;
  });

  return {
    ...geoJson,
    features: filteredFeatures,
  };
}

const HighlightLastAndPreviousConnectingLines = ({
  showConnectingLines,
  crewMemberDayWise,
  filteredGeoJson,
  lookUpData,
  hoveredPolygonId,
  hoveredGeoJson,
}) => {
  const [prevLineData, setPrevLineData] = useState([]);
  const [nextLineData, setNextLineData] = useState([]);
  const [wayPointsData, setWayPointsData] = useState(null);
  const [centerLine, setCenterLine] = useState(null);
  useEffect(() => {
    if (hoveredGeoJson) {
      setCenterLine(filterLineStrings(hoveredGeoJson));
    }
  }, [hoveredGeoJson]);
  useEffect(() => {
    if (crewMemberDayWise && hoveredPolygonId) {
      const nextConnectingLines = [];
      const prevConnectingLines = [];
      const wayPointsArray = [];
      Object.entries(crewMemberDayWise).forEach(([crewMemberId, crewData]) => {
        crewData.day.forEach((dayData) => {
          const routePlan = getPreviousAndNextLineData(
            hoveredPolygonId,
            dayData?.parkingId,
            lookUpData
          );

          routePlan?.route?.nextConnectingRoute?.forEach((route) => {
            const entryPoint = route?.entryPoint;
            const exitPoint = route?.exitPoint;

            const transformedObject = {
              source: [entryPoint?.lon, entryPoint?.lat],
              target: [exitPoint?.lon, exitPoint?.lat],
              color: hexToRgb(crewData.crewMemberColor),
            };

            nextConnectingLines.push(transformedObject);
          });
          routePlan?.route?.prevConnectingRoute?.forEach((route) => {
            const entryPoint = route?.entryPoint;
            const exitPoint = route?.exitPoint;

            const transformedObject = {
              source: [entryPoint?.lon, entryPoint?.lat],
              target: [exitPoint?.lon, exitPoint?.lat],
              color: hexToRgb(crewData.crewMemberColor),
            };

            prevConnectingLines.push(transformedObject);
          });
          setWayPointsData({
            type: "FeatureCollection",
            features: routePlan.wayPointsGeoJson,
          });
          routePlan.wayPointsGeoJson.forEach((feature) => {
            wayPointsArray.push(feature);
          });
        });
      });

      setPrevLineData(prevConnectingLines);
      setNextLineData(nextConnectingLines);
      setWayPointsData({
        type: "FeatureCollection",
        features: wayPointsArray,
      });
    } else {
      setPrevLineData([]);
      setNextLineData([]);
      setWayPointsData([]);
    }
  }, [crewMemberDayWise, filteredGeoJson, hoveredPolygonId]);
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(
        prevLineData,
        nextLineData,
        wayPointsData,
        centerLine,
        showConnectingLines
      )}
    />
  );
};

export default HighlightLastAndPreviousConnectingLines;
