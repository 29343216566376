import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DeckGlOverlay } from "./deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";

function getDeckGlLayers(data,showNumberCircles) {
  if (!data) return [];
  return [
    new GeoJsonLayer({
      id: "wayPointsLayer",
      data: data,
      pointType: "circle+text",
      visible: showNumberCircles ? true : false,
      getPosition: (d) => d.geometry.coordinates,
      getFillColor: (d) => {
        const color = d.properties.color.slice(1); // Remove '#' from color
        return [parseInt(color.substring(0, 2), 16), parseInt(color.substring(2, 4), 16), parseInt(color.substring(4, 6), 16), 200];
      },
      getLineColor: (d) => {
        const color = d.properties.color.slice(1); // Remove '#' from color
        return [parseInt(color.substring(0, 2), 16), parseInt(color.substring(2, 4), 16), parseInt(color.substring(4, 6), 16), 200];
      },
      stroked: true,
      iconBillboard: true,
      getPointRadius: 1,
      pointSizeScale: 1,
      pointRadiusMaxPixels: 1,
      pickable: true,
    }),
  ];
}

const WayPointsMarker = ({
  crewMemberDayWise,
  selectedServiceIds,
  filteredGeoJson,
}) => {
  const [numberMarkersJson, setNumberMarkersJson] = useState(null);
  const lookUpData = useSelector((state) => state.global.lookUpData);

  useEffect(() => {
    if (crewMemberDayWise) {
        const crewMarkerDataJson = {
          type: "FeatureCollection",
          features: Object.entries(crewMemberDayWise).flatMap(
            ([crewMemberId, crewMember]) =>
              crewMember.day.flatMap((dayInfo) => {
                const filteredPolygonIds = dayInfo.polygon.filter((polygonId) => {
                  const polygon = lookUpData.polygons[polygonId];
                  return polygon && selectedServiceIds.includes(polygon.serviceId.toString());
                });
    
                return filteredPolygonIds.flatMap((polygonId) => {
                  const polygon = lookUpData.polygons[polygonId];
                  if (!polygonId || !polygon?.wayPoints) {
                    return []; // Ensures no undefined entries if no waypoints
                  }
    
                  return polygon.wayPoints.map((wayPoint, index) => { // Using map here as each waypoint produces one feature
                    return {
                      type: "Feature",
                      geometry: {
                        type: "Point",
                        coordinates: [parseFloat(wayPoint.lon), parseFloat(wayPoint.lat)]
                      },
                      properties: {
                        id: `${crewMemberId}-${polygonId}-${index}`, // Ensures unique ID for each waypoint
                        crewMemberId: crewMemberId,
                        polygonId: polygonId, // Optionally add polygonId if useful
                        color: crewMember.crewMemberColor
                      }
                    };
                  });
                });
              })
          ),
        };
    
        setNumberMarkersJson(crewMarkerDataJson);
      }
  }, [crewMemberDayWise, filteredGeoJson]);

  return (
    <DeckGlOverlay layers={getDeckGlLayers(numberMarkersJson,true)} />
  );
};

export default WayPointsMarker;
